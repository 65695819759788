import { FunctionComponent } from "react";
import "./Banner.scss";

const Component: FunctionComponent<{ text: string }> = ({ text }) => {
  return (
    <div className="text-white" style={{backgroundColor: "#006699"}}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="my-3" >{text}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Component;