import SimpleRow from 'components/SimpleRow/SimpleRow';
import Banner from 'components/Banner/Banner';

function Page() {
  return (
    <>
      <Banner text="Nossos Voluntários" />
      <SimpleRow backgroundColor="#f5f8ff">
        <div className="col-md-12 mb-5 pb-5">
          <h2 className='d-none'>Nossos Voluntários</h2>
          <div className="row">
            <h2 className='ml-3'>Pagina esta sendo atualizada</h2>
            <div className="col-md-3 mb-5 pb-5 d-none">
              <ul style={{ paddingLeft: "15px" }}>
                <li>Alessandra U Dantas</li>
                <li>Alessandro Vieira</li>
                <li>Alexandre Corrêa</li>
                <li>Alexandre Guimarães</li>
                <li>Alexandre Jorge</li>
                <li>Alexandre Simões</li>
                <li>Alvaro Quintaneiro Da Silva</li>
                <li>Ana Beatriz Fernandes</li>
                <li>Anderson Nunes</li>
                <li>Andre Antunes</li>
                <li>Andre Bueno</li>
                <li>Andre Coutinho</li>
                <li>Andre Nunes</li>
                <li>Andre Macedo</li>
                <li>Andre Verri</li>
                <li>Arnaud Juchler</li>
                <li>Byron Souza</li>
                <li>Camila Castanho</li>
                <li>Carla Camilo</li>
                <li>Carlos Leão</li>
                <li>Christiane Vieira</li>
              </ul>
            </div>
            <div className="col-md-3 mb-5 pb-5 d-none">
              <ul style={{ paddingLeft: "15px" }}>
                <li>Christianne Wright</li>
                <li>Christine Locke</li>
                <li>Cristina Schuch</li>
                <li>Daniel Almeida Pereira</li>
                <li>Daniel Cardoso</li>
                <li>Daniela Vietri</li>
                <li>Diego Silva</li>
                <li>Eder A Martins</li>
                <li>Eduardo Manzano</li>
                <li>Erica Cordeiro</li>
                <li>Erika Bernardino</li>
                <li>Francisco Antunes</li>
                <li>Gabriel Borba</li>
                <li>Gilberto Falk</li>
                <li>Gustavo Corrêia</li>
                <li>Gustavo Limongi</li>
                <li>Gustavo Meiva</li>
                <li>Gabriel da Silva</li>
                <li>Gabriel Matheus Pinto</li>
                <li>Jean Zolet</li>
                <li>João Kovalchuk</li>
              </ul>
            </div>
            <div className="col-md-3 mb-5 pb-5 d-none">
              <ul style={{ paddingLeft: "15px" }}>
                <li>João Ribeiro</li>
                <li>Juliana Mota</li>
                <li>Junior Baldo</li>
                <li>Karin Anderson</li>
                <li>Kayte Santana</li>
                <li>Leonardo Nader</li>
                <li>Leticia Oliveira</li>
                <li>Leticia Peres</li>
                <li>Linconl Araujo</li>
                <li>Luis Fernando Barbi</li>
                <li>Luiz Fernando Benazzi</li>
                <li>Marcelo Perracini</li>
                <li>Marcelo Villar</li>
                <li>Marco Tulio Ribeiro Ricci</li>
                <li>Marcos França</li>
                <li>Maria Eduarda Martins</li>
                <li>Maria Isabelle Neves</li>
                <li>Mario Kataoka Filho</li>
                <li>Michael R Anderson</li>
                <li>Nathalia Castro</li>
                <li>Paulo Alcidori</li>
              </ul>
            </div>
            <div className="col-md-3 mb-5 pb-5 d-none">
              <ul style={{ paddingLeft: "15px" }}>
                <li>Paulo Eduardo</li>
                <li>Paulo Lourenco</li>
                <li>Priscilla Camarani</li>
                <li>Raj Manchanda</li>
                <li>Rafael Delazeri Gruenevald</li>
                <li>Rafael Regattieri</li>
                <li>Renan Demori</li>
                <li>Renan Pinheiro</li>
                <li>Renata Souza</li>
                <li>Rodolfo Mazutti Meleiro</li>
                <li>Sandra Luz</li>
                <li>Sandro Amico</li>
                <li>Sergio Detoie</li>
                <li>Sergio Nascimento</li>
                <li>Simone Souza</li>
                <li>Scott Beckwith</li>
                <li>Tiago Teles Fernandes</li>
                <li>Thuany Maraschin</li>
                <li>Vinicius Luca</li>
                <li>Wellington Bonifacio</li>
                <li>Zane Clark</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-12 d-none">
          <h2>Nossos agradecimentos aos Ex-Voluntários</h2>
          <div className="row">
            <div className="col-md-3 mb-5 pb-5">
              <ul style={{ paddingLeft: "15px" }}>
                <li>Breno Coelho</li>
                <li>Jorge Nasseh</li>
                <li>Rodrigo Berardine</li>
                <li>Ieda Mertz</li>
                <li>Fabiana Ajaj</li>
                <li>Gorgio Solinas </li>
                <li>Renato Felicio</li>
                <li>Rodrigo Ajaj</li>
                <li>Rodrigo Marques</li>
                <li>Roseli Rizzo</li>
                <li>Marcelo Webster</li>
                <li>Pedro Gonzaga</li>
              </ul>
            </div>
          </div>
        </div>
      </SimpleRow>
    </>
  );
}

export default Page;
