import { FunctionComponent } from "react";
import "./Alert.scss";

//import nota from 'resources/nota_sobre_desafio_academico.pdf'
import { NavLink } from "react-router-dom";

const Component: FunctionComponent<{}> = () => {
  return (
    <div
      className="text-black text-center py-1"
      style={{ backgroundColor: "#fbc105", color: "#000000" }}
    >
      <NavLink to="/desafioAcademico/o-desafio-da-ponte">
        As regras do Desafio Acadêmico - Prova da Ponte 2024 já estão
        disponíveis
        <i
          className="d-none fas fa-file-pdf ml-1"
          style={{ color: "#006699" }}
        ></i>
      </NavLink>
    </div>
  );
};

export default Component;

