import SimpleRow from 'components/SimpleRow/SimpleRow';
import FolderRow from 'components/FolderRow/FolderRow';
import Banner from 'components/Banner/Banner';

import { Route } from 'react-router-dom';

import altair2015 from 'resources/pdfs/2015c/Altair - A Integração de Modelos de Falhas em Constituintes do Compósito durante Otimização de Forma de Estruturas.pdf'
import ascent2015 from 'resources/pdfs/2015c/AscentAerospace - Comparação de materiais composites e invar para moldes visando a manufatura precisa de peças em composites.pdf'
import barrac2015 from 'resources/pdfs/2015c/Barracuda - Absorção de resina em espumas de núcleo sandwich.pdf'
import embrae2015 from 'resources/pdfs/2015c/Embraer - Tendências na ind aeronáutica e como os materiais avançados podem contribuir para as futuras demandas desse mercado.pdf'
import evonik2015 from 'resources/pdfs/2015c/Evonik - Complex shaped PMI Foam Cores for highly efficient FRP Composite Sandwich Fabrication.pdf'
import feipdf2015 from 'resources/pdfs/2015c/Fei - Caracterização experimental da rigidez à flexão em material compósito carbono_epóxi.pdf'
import heraeu2015 from 'resources/pdfs/2015c/Heraeus - Sistemas de aquecimento por infravermelhos para compósitos – vantagens no seu desenvolvimento e fabricação.pdf'
import inpepd2015 from 'resources/pdfs/2015c/Inpe - Efeito dos parâmetros de CVD sobre a resistência à tração das fibras de carbono com o crescimento de nanotubos....pdf'
import itapdf2015 from 'resources/pdfs/2015c/Ita - Modos de falha em compressão de compósitos fibra de carbono_epóxi.pdf'
import tohopd2015 from 'resources/pdfs/2015c/Toho - Peças tridimensionais com o uso de laminados espessos de PEEK fibra de carbono.pdf'
import ufrgsp2015 from 'resources/pdfs/2015c/UFRGS - Análise numérica e experimental do comportamento em tração e dobramento de cabos compósitos.pdf'

import ascent2017 from 'resources/pdfs/2017c/ascentaerospace.pdf'
import boeing2017 from 'resources/pdfs/2017c/boeing.pdf'
import embrae2017 from 'resources/pdfs/2017c/embraer.pdf'
import greggb2017 from 'resources/pdfs/2017c/greggbalko_sampe.pdf'
import olinpd2017 from 'resources/pdfs/2017c/olin.pdf'
import siemen2017 from 'resources/pdfs/2017c/SIEMENS.pdf'
import techni2017 from 'resources/pdfs/2017c/technip_.pdf'
import unespp2017 from 'resources/pdfs/2017c/unesp.pdf'

import abertu2018 from 'resources/pdfs/2018c/congresso_sampe_abertura.pdf'
import alltec2018 from 'resources/pdfs/2018c/congresso_sampe_alltec.pdf'
import diabpd2018 from 'resources/pdfs/2018c/congresso_sampe_diab.pdf'
import evonik2018 from 'resources/pdfs/2018c/congresso_sampe_evonik.pdf'
import olinpd2018 from 'resources/pdfs/2018c/congresso_sampe_olin.pdf'
import sigmat2018 from 'resources/pdfs/2018c/congresso_sampe_sigmatex.pdf'
import solvay2018 from 'resources/pdfs/2018c/congresso_sampe_solvay.pdf'
import teijin2018 from 'resources/pdfs/2018c/congresso_sampe_teijin.pdf'

import c_1_2021 from 'resources/pdfs/congresso2021/Brazil SAMPE 1221 Solvay.pdf'
//import c_2_2021 from 'resources/pdfs/congresso2021/Comitê PD&I Infográfico.pdf'
import c_3_2021 from 'resources/pdfs/congresso2021/Sampe Brasil - Congresso Internacional - RMM.pdf'
import c_4_2021 from 'resources/pdfs/congresso2021/Sampepresentation_V2_112021.pptx.pdf'
import c_5_2021 from 'resources/pdfs/congresso2021/Thermoplastic Composites for Aerospace Sector_a trend_ALLTEC_SAMPE Dec2021.pdf'

import c_1_2022 from 'resources/pdfs/congresso2022/1.pdf'
import c_2_2022 from 'resources/pdfs/congresso2022/2.pdf'
import c_3_2022 from 'resources/pdfs/congresso2022/3.pdf'
import c_4_2022 from 'resources/pdfs/congresso2022/4.pdf'
import c_5_2022 from 'resources/pdfs/congresso2022/5.pdf'
import c_6_2022 from 'resources/pdfs/congresso2022/6.pdf'
import c_7_2022 from 'resources/pdfs/congresso2022/7.pdf'
import c_8_2022 from 'resources/pdfs/congresso2022/8.pdf'
import c_9_2022 from 'resources/pdfs/congresso2022/9.pdf'
import c_10_2022 from 'resources/pdfs/congresso2022/10.pdf'
import c_11_2022 from 'resources/pdfs/congresso2022/11.pdf'


function Page() {
  return (
    <>
      <Route exact path={["/congresso/2012", "/congresso"]}>
        <Banner text="2012 Congresso I" />
        <SimpleRow backgroundColor="#f5f8ff" padBottom>
          <div className="col-12">
            <h2>Patrocinadores</h2>
            <p>Reichhold, Texiglass, Huntsman, Transtécnica, Elekeiroz, M.Cassab, Jushi, Owens Corning, Masterpol, CPIC, Bayer, Milliken, Ashland, UBE, Amino e Brandeirante Brazmo</p>
            <h2>Suporte &amp; Organização</h2>
            <p>Artsim, Feiplar e Feiplur</p>
          </div>
        </SimpleRow>
      </Route>
      <Route path="/congresso/2014">
        <Banner text="2014 Congresso II" />
        <SimpleRow backgroundColor="#f5f8ff" padBottom>
          <div className="col-12">
            <h2>Patrocinadores</h2>
            <p>Jushi, Bayer, Transtécnica,Texiglass, Reichhold, Huntsman, MCassab, Purcom, Bandeirante Brazmo, Amino, Abcol, MVP, VI Fiberglass, NovaFiber, Ube, Univar, ChemTrend e Redelease</p>
            <h2>Suporte &amp; Organização</h2>
            <p>Artsim, Feiplar e Feiplur</p>
          </div>
        </SimpleRow>
        <FolderRow backgroundColor="#ffffff">
          <div className="col-md-12" >
            <h1>Palestras</h1>
            <p>Em breve</p>
            {/*<ul style={{ paddingLeft: "15px" }}>
              <li>
                <a href={example} download="example" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i>  </a>
                <p>
                  <b>

                  </b>
                </p>
              </li>

  </ul>*/}
          </div>
        </FolderRow>
      </Route>
      <Route path="/congresso/2015">
        <Banner text="2015 Congresso III" />
        <SimpleRow backgroundColor="#f5f8ff" padBottom>
          <div className="col-12">
            <h2>Patrocinadores</h2>
            <p>Huntsman, NovaFiber, Reichhold, Texiglass, Ube</p>
            <h2>Suporte &amp; Organização</h2>
            <p>Artsim, Feiplar e Feiplur</p>
          </div>
        </SimpleRow>
        <FolderRow backgroundColor="#ffffff">
          <div className="col-md-12" >
            <h1>Palestras</h1>
            <ul style={{ paddingLeft: "15px" }}>
              <li>
                <a href={embrae2015} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Tendências na indústria aeronáutica e como os materiais avançados podem
                  contribuir para as futuras demandas desse mercado </a>
                <p>
                  <b>
                    Antonio João Carmesini, Diretor de Engenharia da Manufatura da Embraer SA
                  </b>
                </p>
              </li>
              <li>
                <a href={ufrgsp2015} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Análise numérica e experimental do comportamento em tração e dobramento
                  de cabos compósitos</a>
                <p>
                  <b>
                    Eduardo A. W. de Menezes, Laís V. Silva, Carlos A. Cimini Jr. e Sandro C. Amico,
                    UFRGS
                  </b>
                </p>
              </li>
              <li>
                <a href={barrac2015} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Absorção de resina em espumas de núcleo sandwich</a>
                <p>
                  <b>
                    Jorge Nasseh, Barracuda Composites (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={tohopd2015} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Peças tridimensionais com o uso de laminados espessos de PEEK/fibra de
                  carbono</a>
                <p>
                  <b>
                    Joe Spangler, Toho (EUA)
                  </b>
                </p>
              </li>
              <li>
                <a href={evonik2015} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i>
                  Núcleos de espuma de PMI com formas complexas p/ a fabricação altamente
                  eficiente de composites sandwich com fibra p/ aplicações de baixo a alto
                  volumes
                </a>
                <p>
                  <b>
                    M. Alexander Roth e Fábio Tufano (Alemanha/Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={altair2015} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> A Integração de Modelos de Falhas em Constituintes do Compósito durante
                  Otimização de Forma de Estruturas </a>
                <p>
                  <b>
                    Robert N. Yancey, Jeffrey A. Wollschlager e Jacob Fish – Altair Engineering
                    (EUA)
                  </b>
                </p>
              </li>
              <li>
                <a href={itapdf2015} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Modos de falha em compressão de compósitos fibra de carbono/epóxi</a>
                <p>
                  <b>
                    Carlos Vinícios Opelt, Geraldo Mauricio Cândido e Mirabel Cerqueira Rezende
                    –ITA-Instituto Tecnológico de Aeronáutica e Universidade Federal de São Paulo
                    (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={inpepd2015} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Efeito dos parâmetros de CVD sobre a resistência à tração das fibras de
                  carbono com o crescimento de nanotubos de carbono, por meio do teste de
                  tração de monofilamentos</a>
                <p>
                  <b>
                    Marines Carvajal Gomes, Lays Dias Ribeiro, Fabio Santos da Silva e Evaldo José
                    Corat – INPE-Instituto Nacional de Pesquisas Espaciais (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={feipdf2015} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Caracterização experimental da rigidez à flexão em material compósito
                  carbono-epóxi</a>
                <p>
                  <b>
                    Gabriel Prosofsky de Araujo e Gigliola Salerno, Centro Universitário da FEI
                    (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={heraeu2015} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Sistemas de aquecimento por infravermelhos para compósitos – vantagens no
                  seu desenvolvimento e fabricação</a>
                <p>
                  <b>
                    Freddy Baruch, Heraeus Noblelight (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={ascent2015} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Comparação de materiais composites e invar para moldes visando a
                  manufatura precisa de peças em composites</a>
                <p>
                  <b>
                    Paul Walsh, Mike Fox e Bob Mitchell, Ascent Aerospace (EUA)
                  </b>
                </p>
              </li>
            </ul>
          </div>
        </FolderRow>
      </Route>
      <Route path="/congresso/2016">
        <Banner text="2016 Congresso IV" />
        <SimpleRow backgroundColor="#f5f8ff" padBottom>
          <div className="col-md-12">
            <h1>Não disponível no momento</h1>
          </div>
        </SimpleRow>
      </Route>
      <Route path="/congresso/2017">
        <Banner text="2017 Congresso V" />
        <SimpleRow backgroundColor="#f5f8ff" padBottom>
          <div className="col-12">
            <h2>Patrocinadores</h2>
            <p>Arkema, A. Schulman, Diab, Evonik, Heraeus e Teijin</p>
            <h2>Suporte &amp; Organização</h2>
            <p>Artsim</p>
          </div>
        </SimpleRow>
        <FolderRow backgroundColor="#ffffff">
          <div className="col-md-12" >
            <h1>Palestras</h1>
            <ul style={{ paddingLeft: "15px" }}>
              <li>
                <a href={greggb2017} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Abertura </a>
                <p>
                  <b>
                    Gregg Balko, SAMPE EUA
                  </b>
                </p>
              </li>
              <li>
                <a href={embrae2017} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Desafios de P&amp;D em composites para as estruturas aéreas Embraer </a>
                <p>
                  <b>
                    Luciana Selmi Marques, Composites R&amp;D Project Manager
                  </b>
                </p>
              </li>
              <li>
                <a href={techni2017} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Redução de custos com armaduras de compósito em flexíveis </a>
                <p>
                  <b>
                    Patricia Camargo, TechnipFMC Brazil
                  </b>
                </p>
              </li>
              <li>
                <a href={boeing2017} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Boeing Overview </a>
                <p>
                  <b>
                    Catherine Parrish, Boeing
                  </b>
                </p>
              </li>
              <li>
                <a href={siemen2017} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Metodologias de design de composites otimizadas que permitem trocas rápidas </a>
                <p>
                  <b>
                    Torrey Sullivan e Sean O’hearn, Siemens
                  </b>
                </p>
              </li>
              <li>
                <a href={olinpd2017} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Conceitos básicos de resinas epóxi aplicados a tecnologia de compósitos </a>
                <p>
                  <b>
                    Marcos França, Olin
                  </b>
                </p>
              </li>
              <li>
                <i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Análise numérica e seleção de núcleo para coberturas nacelle de pás eólicas
                <p>
                  <b>
                    Jorge Nasseh, Barracuda
                  </b>
                </p>
              </li>
              <li>
                <i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Desenvolvimento de materiais compósitos híbridos, composto de dois tipos de fibras e reforçado com resina epóxi
                <p>
                  <b>
                    Letícia Ramos Timarco e Arnaldo Carlos Morelli, Instituto Federal de São Paulo
                  </b>
                </p>
              </li>
              <li>
                <a href={ascent2017} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Invar híbrido/Moldes layup de fibra de carbono para manufatura de peças em composites </a>
                <p>
                  <b>
                    Sean Henson e Paul Walsh, Ascent Aerospace
                  </b>
                </p>
              </li>
              <li>
                <a href={unespp2017} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Efeito sobre a absorção de energia de micro-ondas de não-tecido de fibra de carbono metalizado com níquel devido à mudança de gramatura e ataque por ácido </a>
                <p>
                  <b>
                    Daniel Silveira, Universidade de São Paulo UNESP, Escola de Engenharia de Guaratinguetá - FEG
                  </b>
                </p>
              </li>
            </ul>
          </div>
        </FolderRow>
      </Route>
      <Route path="/congresso/2018">
        <Banner text="2018 Congresso VI" />
        <SimpleRow backgroundColor="#f5f8ff" padBottom>
          <div className="col-12">
            <h2>Patrocinadores</h2>
            <p>Evonik, Instron, Saertex, Solvay e Texiglass</p>
            <h2>Suporte &amp; Organização</h2>
            <p>Feiplar e Feiplur</p>
          </div>
        </SimpleRow>
        <FolderRow backgroundColor="#ffffff">
          <div className="col-md-12" >
            <h1>Palestras</h1>
            <ul style={{ paddingLeft: "15px" }}>
              <li>
                <a href={abertu2018} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Abertura SAMPE </a>
                <p>
                  <b>
                    Gregg Balk e Jorge Nasseh (EUA e Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={sigmat2018} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> O futuro da fibra de carbono na indústria automotiva </a>
                <p>
                  <b>
                    Scott Tolson, Sigmatex (Inglaterra)
                  </b>
                </p>
              </li>
              <li>
                <a href={solvay2018} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Composites termoplásticos na indústria de óleo & gás </a>
                <p>
                  <b>
                    Kim Gingras, Solvay (EUA)
                  </b>
                </p>
              </li>
              <li>
                <a href={diabpd2018} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> O futuro dos materiais de núcleo na energia eólica </a>
                <p>
                  <b>
                    Magalena Sandstrom, Diab Group (Suécia)
                  </b>
                </p>
              </li>
              <li>
                <a href={evonik2018} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Qualificação de termoplásticos reforçados com fibra de carbono em tubulações de composites termoplásticos para aplicações de óleo e gás </a>
                <p>
                  <b>
                    Carsten Schuett, Evonik (Alemanha)
                  </b>
                </p>
              </li>
              <li>
                <a href={teijin2018} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Os efeitos de repetidas operações de calor e pressão nas propriedades físicas e mecânicas de laminados com PEEK </a>
                <p>
                  <b>
                    Joe Spangler e Jean Phillipe Canart, Teijin Carbon America (EUA)
                  </b>
                </p>
              </li>
              <li>
                <a href={olinpd2018} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Sistemas epóxi tenacificados para vasos de pressão </a>
                <p>
                  <b>
                    Benjamin Benz, OLIN Corporation (EUA e Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={alltec2018} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Influência da técnica de corte em corpos de prova de compósitos, indo além das tolerâncias de normas </a>
                <p>
                  <b>
                    Jose Maria Fernandes Marlet, Alltec Composites (Brasil)
                  </b>
                </p>
              </li>
            </ul>
          </div>
        </FolderRow>
      </Route>
      <Route path="/congresso/2022">
        <Banner text="2022 Congresso VIII" />
        <SimpleRow backgroundColor="#f5f8ff" padBottom>
          <div className="col-12">
            <h2>Patrocinadores</h2>
            <p>Aeris Energy, Alan Harper Composites, Alltec Composites, Baker Hughes, Boeing, Evonik, Gerdau Graphene, Huntsman, Solvay, Tencate Geosynthetics, Texiglass</p>
            <h2>Suporte &amp; Organização</h2>
            <p>Feiplar e Feiplur</p>
          </div>
          <div className='offset-md-0 col-md-8'>
            <iframe width="100%" height="450" src="https://www.youtube.com/embed/iYo_eXh__d8"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true"
              rel="0"></iframe>
          </div>
        </SimpleRow>
        <FolderRow backgroundColor="#ffffff">
          <div className="col-md-12" >
            <h1>Palestras</h1>
            <ul style={{ paddingLeft: "15px" }}>
              <li>
                <a href={c_1_2022} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Development and manufacturing of structural composite parts </a>
                <p>
                  <b>
                    Wellington Mello (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={c_2_2022} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Nano e micro tecnologias em termofixos, laminados e poliuretanos </a>
                <p>
                  <b>
                    Gabriel Nunes (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={c_3_2022} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Mercado náutico &#38; materiais compósitos </a>
                <p>
                  <b>
                    Christiane Vieira (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={c_4_2022} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Composite pressure vessels manufacturing through filament winding process </a>
                <p>
                  <b>
                    Sergio Nascimento (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={c_5_2022} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Sustentabilidade na ótica dos materiais avançados </a>
                <p>
                  <b>
                    Camila Castanho (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={c_6_2022} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Urban Air Mobility Solutions </a>
                <p>
                  <b>
                    Analli Carvalho (Alemanha) e Alexander Roth (França)
                  </b>
                </p>
              </li>
              <li>
                <a href={c_7_2022} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Visão computacional aplicada a ensaios não destrutivos de materiais compósitos </a>
                <p>
                  <b>
                    José Jerônimo Rabelo Faria (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={c_8_2022} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Glas-Powered Pultrusion for Longer Lighter Blades</a>
                <p>
                  <b>
                    Rafael Regattieri (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={c_9_2022} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Novas tecnologias em materiais de processamento </a>
                <p>
                  <b>
                    Leticia Oliveira (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={c_10_2022} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Mapeamento de patentes sobre reciclagem de compósitos termofixos </a>
                <p>
                  <b>
                    Marcos Pini França (Brasil)
                  </b>
                </p>
              </li>
              <li>
                <a href={c_11_2022} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Manufacturing evaluation of braided carbon fiber thermoplastic tape preforms </a>
                <p>
                  <b>
                    Jean-Philippe Canart (Canada)
                  </b>
                </p>
              </li>
              
            </ul>
          </div>
        </FolderRow>
      </Route>
      <Route exact path="/congresso/2021">
        <Banner text="2021 Congresso VII" />
        <SimpleRow backgroundColor="#f5f8ff" padBottom>
          <div className="col-12">
            <h2>Patrocinadores</h2>
            <p>Baker Hughes, Boeing, Evonik, Gerdau Graphene, Huntsman, Solvay, Tencate Geosynthetics, Texiglass</p>
            <h2>Suporte &amp; Organização</h2>
            <p>Artsim</p>
          </div>
          <div className='offset-md-0 col-md-8'>
            <iframe width="100%" height="450" src="https://www.youtube.com/embed/V7U5Vnz2t88"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true"
              rel="0"></iframe>
          </div>
          <div className='offset-md-0 col-md-8'>
            <iframe width="100%" height="450" src="https://www.youtube.com/embed/muNkCmxMQhc"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true"
              rel="0"></iframe>
          </div>
          <div className='offset-md-0 col-md-8'>
            <iframe width="100%" height="450" src="https://www.youtube.com/embed/H6G_pfajHCM"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true"
              rel="0"></iframe>
          </div>
        </SimpleRow>
        <FolderRow backgroundColor="#ffffff">
          <div className="col-md-12" >
            <h1>Palestras</h1>
            <ul style={{ paddingLeft: "15px" }}>
              <li>
                <a href={c_3_2021} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Energia Eólica – Conceito, mercado e aplicação de compósitos </a>
                <p>
                  <b>
                    Rodolfo Meleiro (Brasil)
                  </b>
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-12" >
            <ul style={{ paddingLeft: "15px" }}>
              <li>
                <i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Veículos Urbanos Aéreos – Materiais compósitos
                <p>
                  <b>
                    Ismael Corazza (Brasil), Elton Guiguer (Brasil)
                  </b>
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-12" >
            <ul style={{ paddingLeft: "15px" }}>
              <li>
                <a href={c_5_2021} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Thermoplastic composites for aerospace sector “A Current Trend”  </a>
                <p>
                  <b>
                    Orestes Ferro (Brasil)
                  </b>
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-12" >
            <ul style={{ paddingLeft: "15px" }}>
              <li>
                <i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Recovery of rare earth elements 
                <p>
                  <b>
                    Nathalia Castro (Estados Unidos)
                  </b>
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-12" >
            <ul style={{ paddingLeft: "15px" }}>
              <li>
                <a href={c_4_2021} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Huntsman_MIRALON® </a>
                <p>
                  <b>
                    Dave Gailus (Estados Unidos), Christiane Vieira (Brasil), Luiz Benazzi (Brasil), Alexandre Corrêa (Brasil), Alexander Roth (França)
                  </b>
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-12" >
            <ul style={{ paddingLeft: "15px" }}>
              <li>
                <a href={c_1_2021} ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Impact of Environmental, Social and Land Use Sustainability on the Composite Selection Process </a>
                <p>
                  <b>
                    Trevor McCrea (Estados Unidos)
                  </b>
                </p>
              </li>
            </ul>
          </div>
        </FolderRow>
      </Route>
    </>
  );
}

export default Page;
