import SimpleRow from 'components/SimpleRow/SimpleRow';

import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import pessoa1 from 'resources/img/pessoas/1pessoas.png'
import pessoa2 from 'resources/img/pessoas/2pessoas.png'
import pessoa3 from 'resources/img/pessoas/3pessoas.png'
import pessoa4 from 'resources/img/pessoas/4pessoas.png'
import pessoa5 from 'resources/img/pessoas/5pessoas.png'
import pessoa6 from 'resources/img/pessoas/6pessoas.png'
import pessoa7 from 'resources/img/pessoas/7pessoas.png'
import pessoa8 from 'resources/img/pessoas/8pessoas.png'

function Page() {
  SwiperCore.use([Autoplay]);
  return (
    <>
      <SimpleRow className="bg-primary text-white">
        <div className="col-md-6">
          <h1>Seja um membro</h1>
          <p>Faça parte de nossa comunidade. Unimos acadêmicos, pesquisadores e profissionais do setor de materiais avançados.</p>
          <p>
            Esteja conectado com os principais atores deste mercado por meio de uma rede qualificada e ágil que permite acesso aberto às informações de mercado, novidades, eventos e oportunidade global de troca de experiências.
          </p>
          <p>
            Reforce sua proposta de valor e posicionamento de marca.
          </p>
          <p>
            Apoie atividades de educação e formação de uma nova geração de estudantes e profissionais.
          </p>
          <p>
            Participe de nossos grupos de trabalho e comitês técnicos, como:
          </p>
          <ul style={{ paddingLeft: "15px" }}>
            <li>Pesquisa, Desenvolvimento e Inovação</li>
            <li>Manufatura Aditiva</li>
            <li>Nanotecnologias</li>
            <li>Plataforma Nacional de Veículos Elétricos</li>
            <li>Processos de Manufatura</li>
            <li>Gestão e Certificação</li>
            <li>Sustentabilidade</li>
            <li>Termoplástico Compósitos</li>
          </ul>
          <p className="mb-0">
            <a className="text-white" style={{ fontWeight: "700" }} href="https://forms.gle/sGNkso54at397WfP7" target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt mr-1"></i>Cadastro Pessoa Fisica</a>
          </p>
          <p className="mt-0" style={{ paddingBottom: "80px" }}>
            <a className="text-white" style={{ fontWeight: "700" }} href="https://forms.gle/HRy5rt8xq7udDUJB6" target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt mr-1"></i>Cadastro Pessoa Jurídica</a>
          </p>

        </div>
        <div className="col-md-6">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            autoplay={true}
            style={{ width: "60%" }}
          >
            <SwiperSlide>
              <img className="img-fluid" style={{ width: "100%" }} src={pessoa1} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="img-fluid" style={{ width: "100%" }} src={pessoa2} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="img-fluid" style={{ width: "100%" }} src={pessoa3} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="img-fluid" style={{ width: "100%" }} src={pessoa4} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="img-fluid" style={{ width: "100%" }} src={pessoa5} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="img-fluid" style={{ width: "100%" }} src={pessoa6} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="img-fluid" style={{ width: "100%" }} src={pessoa7} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="img-fluid" style={{ width: "100%" }} src={pessoa8} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      </SimpleRow>
    </>
  );
}

export default Page;
