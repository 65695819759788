import SimpleRow from 'components/SimpleRow/SimpleRow';
import FolderRow from 'components/FolderRow/FolderRow';
import Banner from 'components/Banner/Banner';

import relatoriomensal202107 from 'resources/pdfs/relatorio_mensal_julho_2021.pdf'
import relatoriomensal202108 from 'resources/pdfs/relatorio_mensal_agosto_2021.pdf'
import relatoriomensal202109 from 'resources/pdfs/relatorio_mensal_setembro_2021.pdf'
import relatoriomensal202110 from 'resources/pdfs/relatorio_mensal_outubro_2021.pdf'
import relatoriomensal202111 from 'resources/pdfs/relatorio_mensal_novembro_2021.pdf'
import relatoriomensal202112 from 'resources/pdfs/relatorio_mensal_dezembro_2021.pdf'
import relatoriomensal202112en from 'resources/pdfs/relatorio_mensal_dezembro_2021_en.pdf'

import rm202201 from 'resources/pdfs/rm2022/01.pdf'
import rm202202 from 'resources/pdfs/rm2022/02.pdf'
import rm202203 from 'resources/pdfs/rm2022/03.pdf'
import rm202204 from 'resources/pdfs/rm2022/04.pdf'
import rm202205 from 'resources/pdfs/rm2022/05.pdf'
import rm202206 from 'resources/pdfs/rm2022/06.pdf'
import rm202207 from 'resources/pdfs/rm2022/07.pdf'
import rm202208 from 'resources/pdfs/rm2022/08.pdf'
import rm202209 from 'resources/pdfs/rm2022/09.pdf'
import rm202210 from 'resources/pdfs/rm2022/10.pdf'
import rm202211 from 'resources/pdfs/rm2022/11.pdf'
import rm202212 from 'resources/pdfs/rm2022/12.pdf'
import rm202301 from 'resources/pdfs/rm2022/01.pdf'
import rm202302 from 'resources/pdfs/rm2022/02.pdf'
import rm202303 from 'resources/pdfs/rm2022/03.pdf'
import rm202304 from 'resources/pdfs/rm2022/04.pdf'
import rm202305 from 'resources/pdfs/rm2022/05.pdf'
//import rm202306 from 'resources/pdfs/rm2022/06.pdf'
//import rm202307 from 'resources/pdfs/rm2022/07.pdf'
//import rm202308 from 'resources/pdfs/rm2022/08.pdf'
//import rm202309 from 'resources/pdfs/rm2022/09.pdf'
//import rm202310 from 'resources/pdfs/rm2022/10.pdf'
//import rm202311 from 'resources/pdfs/rm2022/11.pdf'
//import rm202312 from 'resources/pdfs/rm2022/12.pdf'


import relatorio from 'resources/img/relatoriomensal.png'


function Page() {
  return (
    <>
      <Banner text="Relatório Mensal" />
      <SimpleRow backgroundColor="#ffffff" padBottom>
        <div className="col-md-6" style={{ paddingBottom: "100px" }}>
        <h1>2023</h1>
          <ul style={{ listStyle: "none", fontSize: "22px", paddingLeft: "0" }}>
          {/*
            <li>
              <a href={rm202312} download="Relatório_Mensal_Dezembro_2023" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Dezembro 2023</a>
            </li>
            <li>
              <a href={rm202311} download="Relatório_Mensal_Novembro_2023" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Novembro 2023</a>
            </li>
            <li>
              <a href={rm202310} download="Relatório_Mensal_Outubro_2023" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Outubro 2023</a>
            </li>
            <li>
              <a href={rm202309} download="Relatório_Mensal_Setembro_2023" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Setembro 2023</a>
            </li>
            <li>
              <a href={rm202308} download="Relatório_Mensal_Agosto_2023" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Agosto 2023</a>
            </li>
            <li>
              <a href={rm202307} download="Relatório_Mensal_Julho_2023" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Julho 2023</a>
            </li>
            <li>
              <a href={rm202306} download="Relatório_Mensal_Junho_2023" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Junho 2023</a>
            </li>
            */}
            <li>
              <a href={rm202305} download="Relatório_Mensal_Maio_2023" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Maio 2023</a>
            </li>
            <li>
              <a href={rm202304} download="Relatório_Mensal_Abril_2023" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Abril 2023</a>
            </li>
            <li>
              <a href={rm202303} download="Relatório_Mensal_Março_2023" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Março 2023</a>
            </li>
            <li>
              <a href={rm202302} download="Relatório_Mensal_Fevereiro_2023" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Fevereiro 2023</a>
            </li>
            <li>
              <a href={rm202301} download="Relatório_Mensal_Janeiro_2023" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Janeiro 2023</a>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <img src={relatorio} className="img-fluid" alt="" />
        </div>
      </SimpleRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-6" style={{ paddingBottom: "100px" }}>
        <h1>2022</h1>
          <ul style={{ listStyle: "none", fontSize: "22px", paddingLeft: "0" }}>
            <li>
              <a href={rm202212} download="Relatório_Mensal_Dezembro_2022" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Dezembro 2022</a>
            </li>
            <li>
              <a href={rm202211} download="Relatório_Mensal_Novembro_2022" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Novembro 2022</a>
            </li>
            <li>
              <a href={rm202210} download="Relatório_Mensal_Outubro_2022" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Outubro 2022</a>
            </li>
            <li>
              <a href={rm202209} download="Relatório_Mensal_Setembro_2022" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Setembro 2022</a>
            </li>
            <li>
              <a href={rm202208} download="Relatório_Mensal_Agosto_2022" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Agosto 2022</a>
            </li>
            <li>
              <a href={rm202207} download="Relatório_Mensal_Julho_2022" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Julho 2022</a>
            </li>
            <li>
              <a href={rm202206} download="Relatório_Mensal_Junho_2022" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Junho 2022</a>
            </li>
            <li>
              <a href={rm202205} download="Relatório_Mensal_Maio_2022" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Maio 2022</a>
            </li>
            <li>
              <a href={rm202204} download="Relatório_Mensal_Abril_2022" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Abril 2022</a>
            </li>
            <li>
              <a href={rm202203} download="Relatório_Mensal_Março_2022" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Março 2022</a>
            </li>
            <li>
              <a href={rm202202} download="Relatório_Mensal_Fevereiro_2022" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Fevereiro 2022</a>
            </li>
            <li>
              <a href={rm202201} download="Relatório_Mensal_Janeiro_2022" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Janeiro 2022</a>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-12">
        <h1>2021</h1>
          <ul style={{ listStyle: "none", fontSize: "22px", paddingLeft: "0" }}>
            <li>
              <a href={relatoriomensal202112en} download="Relatório_Mensal_Dezembro_2021_en" ><i className="fas fa-file-pdf" style={{ color: "#006699" }}></i> December 2021</a>
            </li>
            <li>
              <a href={relatoriomensal202112} download="Relatório_Mensal_Dezembro_2021" ><i className="fas fa-file-pdf" style={{ color: "#006699" }}></i> Dezembro 2021</a>
            </li>
            <li>
              <a href={relatoriomensal202111} download="Relatório_Mensal_Novembro_2021" ><i className="fas fa-file-pdf" style={{ color: "#006699" }}></i> Novembro 2021</a>
            </li>
            <li>
              <a href={relatoriomensal202110} download="Relatório_Mensal_Outubro_2021" ><i className="fas fa-file-pdf" style={{ color: "#006699" }}></i> Outubro 2021</a>
            </li>
            <li>
              <a href={relatoriomensal202109} download="Relatório_Mensal_Setembro_2021" ><i className="fas fa-file-pdf" style={{ color: "#006699" }}></i> Setembro 2021</a>
            </li>
            <li>
              <a href={relatoriomensal202108} download="Relatório_Mensal_Agosto_2021" ><i className="fas fa-file-pdf" style={{ color: "#006699" }}></i> Agosto 2021</a>
            </li>
            <li>
              <a href={relatoriomensal202107} download="Relatório_Mensal_Julho_2021" ><i className="fas fa-file-pdf" style={{ color: "#006699" }}></i> Julho 2021</a>
            </li>
          </ul>
        </div>
      </FolderRow>
    </>
  );
}

export default Page;
