import SimpleRow from 'components/SimpleRow/SimpleRow';
import FolderRow from 'components/FolderRow/FolderRow';
import Banner from 'components/Banner/Banner';

import placeholder from 'resources/img/iptlel.jfif'
import treinamentos from 'resources/img/treinamentosincompany.png'
import programa from 'resources/img/programaposgraduacao.png'

import analiseFalha from 'resources/cursos/21.06.11.Analise_falha_materiais_polimericos_V1.jpg'
import analiseTermica from 'resources/cursos/21.06.11.Analise_termica_polimeros_V1.jpg'
import compositosPolimericos from 'resources/cursos/21.06.11.Compositos_polimericos_estruturais_V1.jpg'
import EstatisticaEnsaios from 'resources/cursos/21.06.11.Estatistica_ensaios_laboratoriais_V1.jpg'
import introducaoProcessamento from 'resources/cursos/21.06.11.Introducao_processamento_atrito_V1.jpg'
import projetoEstruturas from 'resources/cursos/21.06.11.Projeto_estruturas_material_composito_V1.jpg'
import qualMelhor from 'resources/cursos/21.06.11.Qual_melhor_plastico_V1.jpg'

function Page() {
  return (
    <>
      <Banner text="Cursos"/>
      <SimpleRow backgroundColor="#f5f8ff">
        <div className="col-md-6 mb-5 pb-5">
          <h1>Treinamentos In-Company </h1>
          <h2>Cursos personalizados</h2>
          <p className="mt-4">
            A SAMPE Brasil em conjunto com entidades setoriais e centros tecnológicos está apta a desenhar, organizar e adminstrar a entrega de treinamentos In-Company
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/byron-souza-filho-aaa53a9/"><i className="fab fa-linkedin mr-1"></i>Byron Souza Filho</a>
        </div>
        <div className="col-md-6 mb-5 pb-5">
          <img src={treinamentos} className="img-fluid" alt="" />
        </div>
      </SimpleRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <img src={placeholder} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Cursos de Curta Duração</h1>
          <h2><a href="https://www.ipt.br/unidades_de_negocios/MA/noticias/1533-curso_de_compositos.htm" target="_blank" rel="noreferrer noopener">IPT LEL</a></h2>
          <p className="mt-4">
            Cursos direcionados aos profissionais que pretendem atuar no setor de materiais compósitos
          </p>
          <ul style={{listStyle: "none", paddingLeft: "0"}}>
            <li><a href={analiseFalha} download="Analise de falha de materiais polimericos"><i className="fas fa-file-download mr-1"  />Analise de falha de materiais polimericos</a></li>
            <li><a href={analiseTermica} download="Analise termica de polimeros"><i className="fas fa-file-download mr-1"  />Analise termica de polimeros</a></li>
            <li><a href={compositosPolimericos} download="Compositos e polimericos estruturais"><i className="fas fa-file-download mr-1"  />Compositos e polimericos estruturais</a></li>
            <li><a href={EstatisticaEnsaios} download="Estatistica e ensaios laboratoriais"><i className="fas fa-file-download mr-1"  />Estatistica e ensaios laboratoriais</a></li>
            <li><a href={introducaoProcessamento} download="Introducao ao processamento de atrito"><i className="fas fa-file-download mr-1"  />Introducao ao processamento de atrito</a></li>
            <li><a href={projetoEstruturas} download="Projeto e estruturas de material composito"><i className="fas fa-file-download mr-1"  />Projeto e estruturas de material composito</a></li>
            <li><a href={qualMelhor} download="Qual o melhor plastico"><i className="fas fa-file-download mr-1"  />Qual o melhor plastico</a></li>
          </ul>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/michelle-leali-6637b823/"><i className="fab fa-linkedin mr-1"></i>Michelle Leali Costa</a>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-6">
          <h1>Programa de Pós-Graduação</h1>
          <h2><a href="https://www.ipt.br/unidades_de_negocios/MA/noticias/1533-curso_de_compositos.htm" target="_blank" rel="noreferrer noopener">IPT em parceria com a Almaco</a></h2>
          <p className="mt-4">
            Curso direcionado aos graduados em Design, Administração, Engenharia, Arquitetura e áreas afins; executivos, consultores e docentes interessados em aprofundar conhecimentos na área.
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/eduardo-luiz-machado-792257/"><i className="fab fa-linkedin mr-1"></i>Eduardo Luiz Machado</a>
        </div>
        <div className="col-md-6" style={{ display: "flex", alignSelf: "top", paddingBottom: "60px" }}>
          <img src={programa} className="img-fluid" alt="" />
        </div>
      </FolderRow>
    </>
  );
}

export default Page;
