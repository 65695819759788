import SimpleRow from 'components/SimpleRow/SimpleRow';

function Page() {
  return (
    <>
      <SimpleRow backgroundColor="#f5f8ff">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 mb-5 pb-5">
              <h1 className="text-center my-5">Página não encontrada</h1>
            </div>
          </div>
        </div>
      </SimpleRow>
    </>
  );
}

export default Page;
