import { FunctionComponent } from "react";
import "./FolderRow.scss";

const FolderRow: FunctionComponent<{ children?: any, className?: any, backgroundColor: string, longPadBottom?: boolean }> = ({ children, className = "", backgroundColor, longPadBottom = true }) => {
  return (
    <div className={"FolderRow " + className}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6" style={{ backgroundColor: backgroundColor }} />
          <div className="col-2 tab">
            <svg viewBox="6 6 70 50" preserveAspectRatio="xMinYMin">
              <path d="m 0 0 c 7 1 7 2 15 10 l 45 45 c 0 0 5 5 10 5 l -70 0 z" fill={backgroundColor} fillRule="evenodd" />
            </svg>
          </div>
        </div>
      </div>
      <div style={{backgroundColor: backgroundColor, paddingBottom: longPadBottom ? "120px" : "20px", paddingTop: "40px"}}>
        <div className="container">
          <div className="row">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FolderRow;