import Logo from 'resources/logo.png';
import memberBenefit from 'resources/pdfs/member_benefit.pdf';

import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import "./Menu.scss";

import {
  NavLink, Route
} from "react-router-dom";

export default function Menu() {
  return (
    <div className="bg-primary">
      <div className="container">
        <Navbar className="menu-component justify-content-end" expand="lg" style={{ paddingTop: "5px" }}>
          <a className="text-white" href="https://www.facebook.com/sampebrasiloficial/" target="_blank" rel="noreferrer">
            <i className="fab fa-facebook-square fa-2x mr-2"></i>
          </a>
          <a className="text-white" href="https://www.youtube.com/channel/UCpNurLJD625AKytq1o1YUeA" target="_blank" rel="noreferrer">
            <i className="fab fa-youtube fa-2x mr-2"></i>
          </a>
          <a className="text-white" href="https://www.instagram.com/sampebrasil/" target="_blank" rel="noreferrer">
            <i className="fab fa-instagram fa-2x mr-2"></i>
          </a>
          <a className="text-white" href="https://www.linkedin.com/company/sampe-brasil/mycompany/" target="_blank" rel="noreferrer">
            <i className="fab fa-linkedin fa-2x mr-2"></i>
          </a>
        </Navbar>
        <Navbar className="navbar-dark menu-component" expand="lg" style={{ paddingTop: "5px" }}>
          <NavLink to="/" className="navbar-brand"><img className="img-fluid" style={{ height: "50px" }} src={Logo} alt="Logo SAMPE" /></NavLink>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown title="Institucional" id="institutional-dropdown">
                <NavLink activeClassName="active-link" className="dropdown-item" to="/governanca"> Governança </NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/planejamento-estrategico"> Planejamento Estratégico </NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/relatorio-mensal"> Relatório Mensal </NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/contato"> Contato </NavLink>
              </NavDropdown>
              <NavDropdown title="Associados" id="associados-dropdown">
                <NavLink activeClassName="active-link" className="dropdown-item" to="/nossos-associados">Nossos Associados</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/nossos-voluntarios">Nossos Voluntários</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/seja-um-membro">Seja um Membro</NavLink>
                <a className="dropdown-item" href="https://forms.gle/HRy5rt8xq7udDUJB6" target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt mr-1"></i>Atualizar Cadastro Institucional</a>
                <a className="dropdown-item" href="https://365.sampe.org/" target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt mr-1"></i>Rede Mundial de Associados</a>
                <a className="dropdown-item" href={memberBenefit} download="member_benefit"><i className="fas fa-file-pdf" style={{ color: "#FFFFFF" }}></i> Benefício dos Associados</a>
              </NavDropdown>
              <NavDropdown title="Atividades" id="projetos-dropdown">
                <a className="dropdown-item" href="https://365.sampe.org/events" target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt mr-1"></i>Calendário internacional</a>
                <a className="dropdown-item" href="https://docs.google.com/spreadsheets/d/1M1_5YXiqLA4I7yVUa3vzg3azj60baMpx/edit?usp=sharing&ouid=109445067212271614600&rtpof=true&sd=true" target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt mr-1"></i>Calendário Nacional</a>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/comites">Comitês</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/cursos">Cursos</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/desenvolvimento-humano">Desenvolvimento Humano</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/eventos">Eventos</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/publicacoes">Publicações</NavLink>
                <a className="dropdown-item" href="https://forms.gle/RoAWe98Fe5yfo5NY7" target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt mr-1"></i>Proposta de Novos Projetos</a>
              </NavDropdown>
              <NavLink activeClassName="active-link" className="nav-link" to="/desafioAcademico/">Desafio Acadêmico</NavLink>
              <NavDropdown title="Congresso Internacional" id="associados-dropdown">
                <NavLink activeClassName="active-link" className="dropdown-item" to="/congresso/2022">2022 Congresso VIII</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/congresso/2021">2021 Congresso VII</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/congresso/2018">2018 Congresso VI</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/congresso/2017">2017 Congresso V</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/congresso/2016">2016 Congresso IV</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/congresso/2015">2015 Congresso III</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/congresso/2014">2014 Congresso II</NavLink>
                <NavLink activeClassName="active-link" className="dropdown-item" to="/congresso/2012">2012 Congresso I</NavLink>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Route path="/desafioAcademico/">
          <Navbar className="navbar-dark menu-component pt-4 pb-3" expand="lg">
            <Navbar.Toggle aria-controls="desafio-navbar-nav" />
            <Navbar.Collapse id="desafio-navbar-nav">
              <Nav className="mr-auto">
                <NavLink activeClassName="active-link" className="nav-link" style={{ fontSize: "18px" }} exact to="/desafioAcademico/">O que é</NavLink>
                <NavLink activeClassName="active-link" className="nav-link" style={{ fontSize: "18px" }} to="/desafioAcademico/o-desafio-da-ponte">Prova da Ponte</NavLink>
                <NavLink activeClassName="active-link" className="nav-link" style={{ fontSize: "18px" }} to="/desafioAcademico/inovacao-em-compositos">Inovação em Compósitos</NavLink>
                <NavLink activeClassName="active-link" className="nav-link" style={{ fontSize: "18px" }} to="/desafioAcademico/edicoes-anteriores">Edições anteriores</NavLink>
                <NavLink activeClassName="active-link" className="nav-link" style={{ fontSize: "18px" }} to="/desafioAcademico/material-auxiliar">Material auxiliar</NavLink>
                <NavLink activeClassName="active-link" className="nav-link" style={{ fontSize: "18px" }} to="/desafioAcademico/fale-conosco">Fale conosco</NavLink>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Route>
      </div>
    </div>
  );
}
/**
 * <a download="example" className="nav-link" style={{ fontSize: "18px" }} href={examplePdf} target="_blank" rel="noreferrer">Regras<i className="fas fa-file-download ml-2"></i></a>
                <a className="nav-link" style={{ fontSize: "18px" }} href="https://forms.gle/cf37RB43hyhCw8vC6" target="_blank" rel="noreferrer">Inscreva-se<i className="fas fa-external-link-alt ml-2"></i></a>
 */