import SimpleRow from 'components/SimpleRow/SimpleRow';
import Banner from 'components/Banner/Banner';

function Page() {
  return (
    <>
      <Banner text="Desenvolvimento Humano" />
      <SimpleRow backgroundColor="#f5f8ff" padBottom>
        <div className="col-md-6">
          <h1 className="mt-4">Equipe</h1>
          <p>
            <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/alexandremguimaraes">
              <i className="fab fa-linkedin mr-1"></i>
              Alexandre Guimarães
            </a>
          </p>
          <p>
            <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/abfernandes/">
              <i className="fab fa-linkedin mr-1"></i>
              Ana Beatriz Fernandes - Conselho Consultivo
            </a>
          </p>

        </div>
        <div className="col-md-6">
          <h1 className="mt-4">Atividades</h1>
          <h2>Novembro/2021</h2>
          <a rel="noreferrer noopener" target="_blank" href="https://economia-uol-com-br.cdn.ampproject.org/c/s/economia.uol.com.br/noticias/redacao/2020/11/10/dar-feedback-e-o-maior-ponto-fraco-na-gestao-brasileira-diz-pesquisa.amp.htm">
            <i class="fas fa-external-link-alt mr-1"></i> 5ª Pílula de Conhecimento – Feedback
          </a>
          <h2>Outubro/2021</h2>
          <a rel="noreferrer noopener" target="_blank" href="https://youtu.be/ZjX9J43Swr8">
            <i class="fab fa-youtube"></i> 4ª Pílula de Conhecimento: Sua linguagem corporal pode moldar você
          </a>
          <h2>Agosto/ 2021</h2>
          <a rel="noreferrer noopener" target="_blank" href="https://youtu.be/saXfavo1OQo">
            <i class="fab fa-youtube"></i> 3ª Pílula de Conhecimento - O poder de escutar
          </a>
          <h2>Julho/2021</h2>
          <a rel="noreferrer noopener" target="_blank" href="https://youtu.be/E2mZUbUiZh0">
            <i class="fab fa-youtube"></i> 2ª Pílula de Conhecimento - Foco e Gestão do Tempo
          </a>
          <h2>Junho/2021</h2>
          <a rel="noreferrer noopener" target="_blank" href="https://www.youtube.com/watch?v=iCvmsMzlF7o">
            <i class="fab fa-youtube"></i> 1ª Pílula de Conhecimento - O Poder da Vulnerabilidade
          </a>
        </div>
      </SimpleRow>
    </>
  );
}

export default Page;
