import React from 'react';
import './scss/App.scss';

import {
  BrowserRouter as Router, Route, Switch,
} from "react-router-dom";

import Home from './pages/Home/Home';
import Menu from 'components/Menu/Menu';
import Calendario from 'pages/Calendario/Calendario';
import Comites from 'pages/Comites/Comites';
import SampeEduca from 'pages/SampeEduca/SampeEduca';
import Governanca from 'pages/Governança/Governanca';
import Contato from 'pages/Contato/Contato';
import Example from 'pages/Example/Example';
import DesafioAcademico from 'pages/DesafioAcademico/DesafioAcademico';
import Sponsors from 'components/Sponsors/Sponsors';
import Alert from 'components/Alert/Alert';
import Cursos from 'pages/Cursos/Cursos';
import Eventos from 'pages/Eventos/Eventos';
import Publicacoes from 'pages/Publicacoes/Publicacoes';
import Missao from 'pages/Missao/Missao';
import FloatButton from 'components/FloatButton/FloatButton';
import SejaMembro from 'pages/SejaMembro/SejaMembro';
import RelatorioMensal from 'pages/RelatorioMensal/RelatorioMensal';
import PlanejamentoEstrategico from 'pages/PlanejamentoEstrategico/PlanejamentoEstrategico';
import NossosAssociados from 'pages/NossosAssociados/NossosAssociados';
import NossosVoluntarios from 'pages/NossosVoluntarios/NossosVoluntarios';
import PaginaNaoEncontrada from 'pages/PaginaNaoEncontrada/PaginaNaoEncontrada';
import DesenvolvimentoHumano from 'pages/DesenvolvimentoHumano/DesenvolvimentoHumano'

import Congresso from 'pages/Congressos/Congresso';

function App() {
  return (
    <div className="App">
      <Router>
        <Menu />
        <Switch>
          <Route exact path="/">
            <Home />
            <Sponsors />
          </Route>
          <Route path="/calendario">
            <Calendario />
            <Sponsors />
          </Route>
          <Route path="/comites">
            <Comites />
            <Sponsors />
          </Route>
          <Route path="/sampeEduca">
            <SampeEduca />
            <Sponsors />
          </Route>
          <Route path="/governanca">
            <Governanca />
            <Sponsors />
          </Route>
          <Route path="/contato">
            <Contato />
            <Sponsors />
          </Route>
          <Route path="/missao">
            <Missao />
            <Sponsors />
          </Route>
          <Route path="/estrutura">
            <Example />
            <Sponsors />
          </Route>
          <Route path="/reunioes">
            <Example />
            <Sponsors />
          </Route>
          <Route path="/eventos">
            <Eventos />
            <Sponsors />
          </Route>
          <Route path="/cursos">
            <Cursos />
            <Sponsors />
          </Route>
          <Route path="/publicacoes">
            <Publicacoes />
            <Sponsors />
          </Route>
          <Route path="/desafioAcademico">
            <DesafioAcademico />
            <Sponsors />
          </Route>
          <Route path="/seja-um-membro">
            <SejaMembro />
            <Sponsors />
          </Route>
          <Route path="/relatorio-mensal">
            <RelatorioMensal />
            <Sponsors />
          </Route>
          <Route path="/planejamento-estrategico">
            <PlanejamentoEstrategico />
            <Sponsors />
          </Route>
          <Route path="/congresso">
            <Congresso />
            <Sponsors />
          </Route>
          <Route path="/nossos-associados">
            <NossosAssociados />
            <Sponsors />
          </Route>
          <Route path="/nossos-voluntarios">
            <NossosVoluntarios />
            <Sponsors /> 
          </Route>
          <Route path="/desenvolvimento-humano">
            <DesenvolvimentoHumano />
            <Sponsors /> 
          </Route>
          <Route path="*">
            <PaginaNaoEncontrada />
            <Sponsors /> 
          </Route>
        </Switch>
        <FloatButton />
      </Router>
    </div>
  );
}

export default App;
