import SimpleRow from 'components/SimpleRow/SimpleRow';

import ata from 'resources/pdfs/ata_de_constituicao.pdf'
import estrutura from 'resources/pdfs/estrutura_operacional.pdf'
import estatuto from 'resources/pdfs/estatuto_social.pdf'
import prorrogacao from 'resources/pdfs/SAMPE - Ata Prorrogação do Mandato Diretoria Registro 188.144 03082022 - Venc 30052023.pdf'

import placeholder from 'resources/placeholder.png'


function Governanca() {
  return (
    <>
      <SimpleRow className="bg-primary text-white">
        <div className="col-md-6" style={{marginBottom: "70px"}}>
          <h1>Governança</h1>
          <h2>Documentos Para Download</h2>
          <ul className="pl-1" style={{ listStyleType: "none", fontSize: "22px" }}>
            <li><a href={ata} download="Ata de Constituição" className="text-white"><i className="fas fa-file-pdf" style={{ color: "#FFFFFF" }}></i> Ata de Constituição</a></li>
            <li><a href={estrutura} download="Estrutura Operacional" className="text-white"><i className="fas fa-file-pdf" style={{ color: "#FFFFFF" }}></i> Estrutura Operacional</a></li>
            <li><a href={estatuto} download="Estatuto Social" className="text-white"><i className="fas fa-file-pdf" style={{ color: "#FFFFFF" }}></i> Estatuto Social</a></li>
            <li><a href={prorrogacao} download="SAMPE - Ata Prorrogação do Mandato Diretoria Registro 188.144 03082022 - Venc 30052023" className="text-white"><i className="fas fa-file-pdf" style={{ color: "#FFFFFF" }}></i> Ata Prorrogação do Mandato</a></li>
            {/*
            <li><a href={examplePdf} download="example" className="text-white"><i className="fas fa-file-pdf" style={{ color: "#FFFFFF" }}></i> Código de Conduta</a></li>
            <li><a href={examplePdf} download="example" className="text-white"><i className="fas fa-file-pdf" style={{ color: "#FFFFFF" }}></i> Atestado Trabalho Voluntário</a></li>
            <li><a href={examplePdf} download="example" className="text-white"><i className="fas fa-file-pdf" style={{ color: "#FFFFFF" }}></i> Atas de Assembleia</a></li>
            */}
          </ul>
        </div>
        <div className="col-md-6" style={{ display: "flex", alignSelf: "top", paddingBottom: "60px" }}>
          <img src={placeholder} className="img-fluid" alt="" />
        </div>
      </SimpleRow>
    </>
  );
}

export default Governanca;
