import SimpleRow from "components/SimpleRow/SimpleRow";
import Alert from "components/Alert/Alert";

import empresaseacademia from "resources/img/empresaseacademia.png";
import reciclagem from "resources/img/reciclagem.png";
import sampeEduca from "resources/img/sampeEduca.jpg";
import perspectivas from "resources/img/perspectivas.png";
import titulodesafio from "resources/img/titulo_desafio.jpg";

import FolderRow from "components/FolderRow/FolderRow";

import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.scss";
import Cellphone from "components/Cellphone/Cellphone";
import { Link } from "react-router-dom";

function Home() {
  SwiperCore.use([Autoplay]);
  return (
    <>
      <Alert />
      <SimpleRow className="bg-primary text-white">
        <div className="col-md-6">
          <h1>
            A SAMPE é a maior associação técnica do planeta focada na
            disseminação do conhecimento de materiais e processos de engenharia
            avançados.
          </h1>
          <p className="mt-4">
            Juntos construímos um futuro mais sustentável extraindo o que há de
            melhor dos materiais e processos avançados.
          </p>
          <p>
            Unindo pessoas e disseminando conhecimento através do trabalho
            voluntário.
          </p>
          <p>Siga-nos em nossas redes sociais!</p>
          <p style={{ paddingBottom: "60px" }}>
            <a
              className="text-white"
              href="https://www.facebook.com/sampebrasiloficial/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-facebook-square fa-3x mr-2"></i>
            </a>
            <a
              className="text-white"
              href="https://www.youtube.com/channel/UCpNurLJD625AKytq1o1YUeA"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-youtube fa-3x mr-2"></i>
            </a>
            <a
              className="text-white"
              href="https://www.instagram.com/sampebrasil/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-instagram fa-3x mr-2"></i>
            </a>
            <a
              className="text-white"
              href="https://www.linkedin.com/company/sampe-brasil/mycompany/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-linkedin fa-3x mr-2"></i>
            </a>
          </p>
        </div>
        <div
          className="col-md-6"
          style={{ display: "flex", alignSelf: "flex-end" }}
        >
          <Cellphone />
        </div>
      </SimpleRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-6">
          <h1>Desafio Acadêmico</h1>
          <p className="mt-4">
            O Desafio Acadêmico tem como meta proporcionar aos alunos, dos
            cursos relacionados à área de Tecnologia de Materiais, uma
            experiência real de projeto com materiais e processos avançados,
            obedecendo regras e especificações preestabelecidas, tendo seu
            projeto avaliado e validado por uma banca formada por profissionais
            respeitados da indústria
          </p>
          Cheque{" "}
          <Link exact to="/desafioAcademico/">
            aqui
          </Link>{" "}
          as categorias do desafio
        </div>
        <div className="col-md-6">
          <img src={titulodesafio} className="img-fluid" alt="" />
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <img src={sampeEduca} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Sampe Educa</h1>
          <p className="mt-4">
            É a nova plataforma da Sampe Brasil onde ministraremos cursos voltados para profissionais e estudantes, 
            disseminando conhecimento dedicado ao setor de compósitos, preenchendo assim uma lacuna no cenário brasileiro.
          </p>
          <p className="mt-4">
          </p>
          <p></p>
          <p className="mt-4">
            Para acessar, clique {" "}
            <Link exact to="/sampeEduca" >
                aqui!
            </Link>{" "}
          </p>
        </div>
        
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        
        <div className="col-md-6">
          <h1>Perspectiva de Mercado</h1>
          <p className="mt-4">
            Reunimos profissionais de diversas empresas e setores em webinares
            quinzenais para compartilhar informações atualizadas sobre as
            movimentações em cada mercado.
          </p>
          <p className="mt-4">
            Macroeconomia, política, pandemia, escassez de produto, logística,
            mão de obra, educação, informática, tributos… como os mercado tem
            sido impactados?
          </p>
          <p className="mt-4">
            Quer participar, preencha{" "}
            <a href="https://forms.gle/9VBZkuV1fxkQLBpu5">este formulário</a>
          </p>
        </div>
        <div className="col-md-6">
          <img src={perspectivas} className="img-fluid" alt="" />
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <img src={empresaseacademia} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Parcerias Empresas &amp; Academia</h1>
          <p className="mt-4">
            Participe de nossos webinares e conheça alguns dos diversos projetos
            de inovação associados aos materiais e processos avançados que estão
            sendo conduzidos no Brasil.
          </p>
          <p className="mt-4">
            Fibras (carbono, vidro, aramida), matrizes poliméricas
            (termoplásticas e termorígidas), cerâmicas e metálicas, grafeno,
            nanocompósitos, manufatura digital, reciclagem, dentre outros
            diversos temos que tem sido pauta de projetos de inovação no Brasil.
          </p>
          <p>Venha conhecer alguns deles em nossos webinares!</p>
          <p className="mt-4">
            Quer participar, preencha{" "}
            <a href="https://forms.gle/gPzkW4TGh54RBcSA8">este formulário</a>
          </p>
        </div>
        
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        
        <div className="col-md-6">
          <h1>Reciclagem</h1>
          <h2>Descarte, separação, processamento e re-uso</h2>
          <p className="mt-4">
            Webinares trazendo especialistas para apresentarem o estado da arte
            de processos associados ao descarte, separação, processamento e
            re-uso de materiais avançados.
          </p>
          <a
            rel="noreferrer noopener"
            className="linkedInBigLink"
            target="_blank"
            href="https://www.linkedin.com/in/rafael-regattieri-043b67b7/"
          >
            <i className="fab fa-linkedin mr-1"></i>Rafael Regattieri
          </a>
        </div>
        <div className="col-md-6">
          <img src={reciclagem} className="img-fluid" alt="" />
        </div>
      </FolderRow>
    </>
  );
}

export default Home;
