import SimpleRow from 'components/SimpleRow/SimpleRow';
import Banner from 'components/Banner/Banner';

import planejamentoestrategico2019 from 'resources/pdfs/planejamento_estrategico_2019.pdf'
import missao from 'resources/img/missao.png'

function Page() {
  return (
    <>
      <Banner text="Planejamento Estratégico" />
      <SimpleRow backgroundColor="#f5f8ff" style={{ paddingBottom: "200px" }} padBottom>
        <div className="col-md-6">
          <ul style={{ listStyle: "none", fontSize: "22px", paddingLeft: "0" }}>
            <li>
              <a href={planejamentoestrategico2019} download="Planejamento_Estratégico_2019" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Planejamento Estratégico 2019</a>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <img src={missao} className="img-fluid" alt="" />
        </div>
      </SimpleRow>
    </>
  );
}

export default Page;
