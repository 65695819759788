import SimpleRow from 'components/SimpleRow/SimpleRow';
import FolderRow from 'components/FolderRow/FolderRow';
import Banner from 'components/Banner/Banner';

import caracterizacao from 'resources/img/comites/caracterizacaodemateriais.png'
import manufaturaaditiva from 'resources/img/comites/manufaturaaditiva.png'
import nanotecnologias from 'resources/img/comites/nanotecnologias.png'
import plataforma from 'resources/img/comites/plataformanacional.png'
import gestao from 'resources/img/comites/gestao.png'
import processos from 'resources/img/comites/processos.png'
import sustentabilidade from 'resources/img/comites/sustentabilidade.png'
import termoplastico from 'resources/img/comites/termoplastico.png'

function Page() {
  return (
    <>
      <Banner text="Comitês" />
      <SimpleRow backgroundColor="#f5f8ff">
      <div className="col-md-6" >
          <h1>Pesquisa, Desenvolvimento e Inovação</h1>
          <h2>Melhores práticas</h2>
          <p className="mt-4">
            Grupo de trabalho para mapear as melhores práticas dos processos de pesquisa, desenvolvimento e inovação da indústria de materiais avançados
          </p>
          <a className="linkedInBigLink" rel="noreferrer noopener" target="_blank" href="https://linkedin.com/in/christiane-vieira-965292b"><i className="fab fa-linkedin mr-1"></i>Christiane Vieira</a>
        </div>
        <div className="col-md-6" style={{ paddingBottom: "90px" }}>
          <img src={caracterizacao} className="img-fluid" alt="" />
        </div>
      </SimpleRow>
      {/*
      <FolderRow backgroundColor="#ffffff">
        
        <div className="col-md-6">
          <img src={manufaturaaditiva} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Manufatura Aditiva</h1>
          <h2>Acelerando o lançamento de novos produtos</h2>
          <p className="mt-4">
            Grupo de Trabalho para fomentar a disseminação de conhecimento e o uso dos materiais e processos voltados à produção de peças em impressoras 3D
          </p>
        </div>
      </FolderRow>
      */}
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-6">
          <img src={nanotecnologias} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Nanotecnologias</h1>
          <h2>Criação, manipulação e exploração</h2>
          <p className="mt-4">
            Grupo de Trabalho para fomentar as reflexões necessárias ao uso comercial em larga escala dos materiais produzidos em escala nanométrica
          </p>
          <p>
            <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://linkedin.com/in/alexandrecorrea"><i className="fab fa-linkedin mr-1"></i>Alexandre Correa</a>
          </p>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <h1>Plataforma Nacional de Veículos Elétricos</h1>
          <h2>Terrestre, marítmo ou aéreo</h2>
          <p className="mt-4">
            Grupo de Trabalho para o Uso de Materiais Avançados na Mobilidade criado através da parceria com a Abiquim, Almaco e PNME Elétrica.
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/s%C3%A9rgio-nascimento-56566b7a/"><i className="fab fa-linkedin mr-1"></i>Sergio Nascimento</a>
        </div>
        <div className="col-md-6">
          <img src={plataforma} className="img-fluid" alt="" />
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-6">
          <img src={processos} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Processos de Manufatura</h1>
          <h2>Entendendo os mecanismos de transformação</h2>
          <p className="mt-4">
            Grupo de Trabalho para fomentar a disseminação de conhecimento dos processos de transformação de materiais avançados.
          </p>

          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/le-mo-ol/"><i className="fab fa-linkedin mr-1"></i>Letícia Oliveira</a>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <h1>Gestão e Certificação</h1>
          <h2>Desmistificando a complexidade no processo de certificação</h2>
          <p className="mt-4">
            Grupo de Trabalho dedicado à discussão de temas associados a Gestão e Certificação na área de materiais avançados.
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="linkedin.com/in/alexandre-simões-jorge-consultor-auditor"><i className="fab fa-linkedin mr-1"></i>Alexandre Jorge</a>
        </div>
        <div className="col-md-6">
          <img src={gestao} className="img-fluid" alt="" />
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-6">
          <img src={sustentabilidade} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Sustentabilidade</h1>
          <h2>Fomentando a continuidade dos negócios preservando o planeta</h2>
          <p className="mt-4">
            Grupo de Trabalho dedicado à discussão de Sustentabilidade na área de materiais avançados.
          </p>

          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="linkedin.com/in/camila-castanho"><i className="fab fa-linkedin mr-1"></i>Camila Castanho</a>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <h1>Termoplástico Compósitos</h1>
          <h2>Soluções sustentáveis para alta cadência de produção</h2>
          <p className="mt-4">
            Grupo de Trabalho dedicado à geração e ao compartilhamento de conhecimento associado à tecnologia de termoplásticos compósitos.
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="linkedin.com/in/orestes-ferro-b3ab50142"><i className="fab fa-linkedin mr-1"></i>Orestes Ferro</a>
        </div>
        <div className="col-md-6">
          <img src={termoplastico} className="img-fluid" alt="" />
        </div>
      </FolderRow>

    </>
  );
}

export default Page;
