import SimpleRow from 'components/SimpleRow/SimpleRow';
import FolderRow from 'components/FolderRow/FolderRow';
import Banner from 'components/Banner/Banner';

import desafio from 'resources/img/desafioacademico.png'
import comunidade from 'resources/img/comunidade.png'
import congresso from 'resources/img/congresso.png'
import desafioempreendedor from 'resources/img/desafioempreendedor.png'
import empresaseacademia from 'resources/img/empresaseacademia.png'
import entrevistaceo from 'resources/img/entrevistaceo.png'
import perspectivas from 'resources/img/perspectivas.png'
import porquecompositos from 'resources/img/porquecompositos.png'
import reciclagem from 'resources/img/reciclagem.png'

function Page() {
  return (
    <>
      <Banner text="Eventos" />
      <SimpleRow backgroundColor="#f5f8ff">
        <div className="col-md-6">
          <h1>Apresentando a Comunidade</h1>
          <h2>Apresentando os atores de nosso setor</h2>
          <p className="mt-4">
            Entrevistas online com profissionais que atuam em diversas posições do ecossistema para compartilharem as trajetórias pessoais, acadêmicas e profissionais.
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/le-mo-ol/"><i className="fab fa-linkedin mr-1"></i>Letícia Oliveira</a>
        </div>
        <div className="col-md-6" style={{ display: "flex", alignSelf: "top", paddingBottom: "60px" }}>
          <img src={comunidade} className="img-fluid" alt="" />
        </div>
      </SimpleRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <img src={congresso} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Congresso Internacional</h1>
          <h2>Evento anual com apresentações técnicas</h2>
          <p className="mt-4">
            Congresso Internacional reunindo sumidades técnicas para apresentarem os mais recentes desenvolvimentos e aplicações em materiais e processos de engenharia avançados.
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/simone-martins-souza-063b6658/"><i className="fab fa-linkedin mr-1"></i>Simone Souza</a>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-6">
          <h1>Desafio Acadêmico 2021</h1>
          <h2>Evento global para construção de pontes</h2>
          <p className="mt-4">
            Conhecido mundialmente como "Student Bridge Contest", o Desafio Acadêmico é uma competição entre universitários voltada ao aprendizado do uso da tecnologia de compósitos.
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/pauloalcidorifilho/"><i className="fab fa-linkedin mr-1"></i>Paulo Alcidori</a>
        </div>
        <div className="col-md-6">
          <img src={desafio} className="img-fluid" alt="" />
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <img src={desafioempreendedor} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Desafio Empreendedor</h1>
          <h2>Entregando soluções às demandas reais</h2>
          <p className="mt-4">
            Competição entre micro e pequenas empresas para a elaboração de um projeto que atendam ais requisitos apresentados pela empresa patrocinadora.
          </p>
          Posição Aberta
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-6">
          <h1>Empresas & Academia </h1>
          <h2>Projetos técnicos realizados no Brasil</h2>
          <p className="mt-4">
            Webinares reunindo representantes de empresas e universidades para compartilhar suas experiêcnias na condução de projetos técnicos desenvolvidos
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/sandro-amico/"><i className="fab fa-linkedin mr-1"></i>Sandro Amico</a>
        </div>
        <div className="col-md-6">
          <img src={empresaseacademia} className="img-fluid" alt="" />
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <img src={entrevistaceo} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Entrevista com CEOs</h1>
          <h2>Como pensam os líderes do setor</h2>
          <p className="mt-4">
            Entrevistas online com os líderes das principais organizações que estão transformando o ecossistema brasileiro de materiais e processos de engenharia avançados.
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/sergiodetoie/"><i className="fab fa-linkedin mr-1"></i>Sergio Detoie</a>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-6">
          <h1>Perspectiva de Mercado</h1>
          <h2>Especialistas compartilhando tendências</h2>
          <p className="mt-4">
            Webinares com profissionais de diversos segmentos compartilhando perspectivas sobre as principais tendências mercadológicas com based nos desafios e nas oportunidades
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/s%C3%A9rgio-nascimento-56566b7a/"><i className="fab fa-linkedin mr-1"></i>Sergio Nascimento</a>
        </div>
        <div className="col-md-6">
          <img src={perspectivas} className="img-fluid" alt="" />
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <img src={porquecompositos} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Por que Compósitos?</h1>
          <h2>Motivações que justificaram a escolha certa</h2>
          <p className="mt-4">
            Webinares trazendo profissionais de diversas posições na cadeia de valor de uma determinada aplicação para compartilhar detalhes sobre o processo de seleção de materiais.
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/diego-franco-da-silva-8ba3b371/"><i className="fab fa-linkedin mr-1"></i>Diego Silva</a>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-6">
          <h1>Reciclagem</h1>
          <h2>Descarte, separação, processamento e re-uso</h2>
          <p className="mt-4">
            Webinares trazendo especialistas para apresentarem o estado da arte de processos associados ao descarte, separação, processamento e re-uso de materiais avançados.
          </p>
          <a rel="noreferrer noopener" className="linkedInBigLink" target="_blank" href="https://www.linkedin.com/in/rafael-regattieri-043b67b7/"><i className="fab fa-linkedin mr-1"></i>Rafael Regattieri</a>
        </div>
        <div className="col-md-6">
          <img src={reciclagem} className="img-fluid" alt="" />
        </div>
      </FolderRow>
    </>
  );
}

export default Page;
