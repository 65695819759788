import SimpleRow from 'components/SimpleRow/SimpleRow';
import Cellphone from 'components/Cellphone/Cellphone';

function Contato() {
  return (
    <>
      <SimpleRow className="bg-primary text-white">
        <div className="col-md-6">
          <h1>Contato</h1>
          <p className="mt-4 mb-1">
            E-mail: contato@sampebrasil.org
          </p>
          <p className="mb-1">
            Telefone: <a href="tel:551148375670" style={{ color: "#ffffff" }} target="_blank" rel="noreferrer"> +55 11 4837-5670 </a>
          </p>
          <p className="mb-1">
            Whatsapp: <a href="https://wa.me/5511996603613" style={{ color: "#ffffff" }} target="_blank" rel="noreferrer"> +55 11 99660-3613 </a>
          </p>
          <p className="mb-1">
            Endereço: <a href="https://goo.gl/maps/BpRsQgrSKGBFbvGn9" style={{ color: "#ffffff" }} target="_blank" rel="noreferrer"> Rua Alexandre Dumas,1562 – 9° andar, conjunto 94, São Paulo/SP – CEP 04717-914 </a>
          </p>
          <p className="mb-1 mt-4">
            Siga-nos em nossas redes sociais!
          </p>
          <p style={{ paddingBottom: "60px" }}>
            <a className="text-white" href="https://www.facebook.com/sampebrasiloficial/" target="_blank" rel="noreferrer">
              <i className="fab fa-facebook-square fa-3x mr-2"></i>
            </a>
            <a className="text-white" href="https://www.youtube.com/channel/UCpNurLJD625AKytq1o1YUeA" target="_blank" rel="noreferrer">
              <i className="fab fa-youtube fa-3x mr-2"></i>
            </a>
            <a className="text-white" href="https://www.instagram.com/sampebrasil/?hl=en" target="_blank" rel="noreferrer">
              <i className="fab fa-instagram fa-3x mr-2"></i>
            </a>
            <a className="text-white" href="https://www.linkedin.com/company/sampe-brasil/mycompany/" target="_blank" rel="noreferrer">
              <i className="fab fa-linkedin fa-3x mr-2"></i>
            </a>
          </p>
        </div>
        <div className="col-md-6" style={{ display: "flex", alignSelf: "flex-end" }}>
          <Cellphone />
        </div>
      </SimpleRow>
    </>
  );
}

export default Contato;
