import SimpleRow from 'components/SimpleRow/SimpleRow';
import placeholder from 'resources/placeholder.png'
import FolderRow from 'components/FolderRow/FolderRow';

function Example() {
  return (
    <>
      <SimpleRow className="bg-primary text-white">
        <div className="col-md-6">
          <h1>Quisque aliquam nunc sit amet urna efficitur</h1>
          <h2>Sed ut iaculis nunc, id euismod purus</h2>
          <p className="mt-4">
            Suspendisse tellus purus, euismod eget est ullamcorper, posuere iaculis elit.
          </p>
          <p>
            Pellentesque massa eros, dictum vitae ultrices id, maximus sit amet nisl.
          </p>
          <p>
            Cras pretium sapien quis erat tincidunt scelerisque. Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.
          </p>
          <p style={{ paddingBottom: "60px" }}>
            Vestibulum ac erat sollicitudin, mattis diam id, consequat nulla.
          </p>
        </div>
        <div className="col-md-6" style={{ display: "flex", alignSelf: "top", paddingBottom: "60px" }}>
          <img src={placeholder} className="img-fluid" alt="" />
        </div>
      </SimpleRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <img src={placeholder} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Quisque aliquam nunc sit</h1>
          <h2>Sed ut iaculis nunc, id euismod purus</h2>
          <p className="mt-4">
            Suspendisse tellus purus, euismod eget est ullamcorper, posuere iaculis elit.
          </p>
          <p>
            Pellentesque massa eros, dictum vitae ultrices id, maximus sit amet nisl.
          </p>
          <p>
            Cras pretium sapien quis erat tincidunt scelerisque. Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.
          </p>
          <p style={{ paddingBottom: "60px" }}>
            Vestibulum ac erat sollicitudin, mattis diam id, consequat nulla.
          </p>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-6">
          <h1>Reciclagem</h1>
          <p className="mt-4">
            Suspendisse tellus purus, euismod eget est ullamcorper, posuere iaculis elit.
          </p>
          <p>
            Pellentesque massa eros, dictum vitae ultrices id, maximus sit amet nisl.
          </p>
          <p>
            Cras pretium sapien quis erat tincidunt scelerisque. Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.
          </p>
          <p style={{ paddingBottom: "60px" }}>
            Vestibulum ac erat sollicitudin, mattis diam id, consequat nulla.
          </p>
        </div>
        <div className="col-md-6">
          <img src={placeholder} className="img-fluid" alt="" />
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-12">
          <h1>Por que Compósitos?</h1>
          <h2>Sed ut iaculis nunc, id euismod purus</h2>
          <p className="mt-4">
            Suspendisse tellus purus, euismod eget est ullamcorper, posuere iaculis elit.
          </p>
          <p>
            Pellentesque massa eros, dictum vitae ultrices id, maximus sit amet nisl. Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.  Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.  Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.
          </p>
          <p>
            Duis egestas eget turpis a finibus. Donec dapibus tortor non nisl tincidunt eleifend. Vivamus mattis tempor urna, auctor fringilla sem laoreet id. Sed lobortis sem at urna mollis consequat. Integer sed diam vitae eros eleifend lobortis vitae tristique ante. Nunc ex metus, dignissim a tortor sed, tempus vestibulum erat. Vivamus finibus finibus diam, vel faucibus erat ultrices sit amet. Donec vel felis ante. Nullam rhoncus elementum ipsum, quis lobortis lorem porttitor eu. Phasellus eget euismod sem, in congue magna. Maecenas vehicula euismod mollis.
          </p>
          <p style={{ paddingBottom: "60px" }}>
            Vestibulum ac erat sollicitudin, mattis diam id, consequat nulla.
          </p>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-12">
          <h1>Caracterização de materiais</h1>
          <h2>Sed ut iaculis nunc, id euismod purus</h2>
          <p className="mt-4">
            Suspendisse tellus purus, euismod eget est ullamcorper, posuere iaculis elit.
          </p>
          <p>
            Pellentesque massa eros, dictum vitae ultrices id, maximus sit amet nisl. Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.  Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.  Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.
          </p>
          <p>
            Duis egestas eget turpis a finibus. Donec dapibus tortor non nisl tincidunt eleifend. Vivamus mattis tempor urna, auctor fringilla sem laoreet id. Sed lobortis sem at urna mollis consequat. Integer sed diam vitae eros eleifend lobortis vitae tristique ante. Nunc ex metus, dignissim a tortor sed, tempus vestibulum erat. Vivamus finibus finibus diam, vel faucibus erat ultrices sit amet. Donec vel felis ante. Nullam rhoncus elementum ipsum, quis lobortis lorem porttitor eu. Phasellus eget euismod sem, in congue magna. Maecenas vehicula euismod mollis.
          </p>
          <p style={{ paddingBottom: "60px" }}>
            Vestibulum ac erat sollicitudin, mattis diam id, consequat nulla.
          </p>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <img src={placeholder} className="img-fluid" alt="" />
        </div>
        <div className="col-md-6">
          <h1>Manufatura Aditiva</h1>
          <h2>Sed ut iaculis nunc, id euismod purus</h2>
          <p className="mt-4">
            Suspendisse tellus purus, euismod eget est ullamcorper, posuere iaculis elit.
          </p>
          <p>
            Pellentesque massa eros, dictum vitae ultrices id, maximus sit amet nisl.
          </p>
          <p>
            Cras pretium sapien quis erat tincidunt scelerisque. Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.
          </p>
          <p style={{ paddingBottom: "60px" }}>
            Vestibulum ac erat sollicitudin, mattis diam id, consequat nulla.
          </p>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-6">
          <h1>Entrevista com CEOs</h1>
          <p className="mt-4">
            Suspendisse tellus purus, euismod eget est ullamcorper, posuere iaculis elit.
          </p>
          <p>
            Pellentesque massa eros, dictum vitae ultrices id, maximus sit amet nisl.
          </p>
          <p>
            Cras pretium sapien quis erat tincidunt scelerisque. Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.
          </p>
          <p style={{ paddingBottom: "60px" }}>
            Vestibulum ac erat sollicitudin, mattis diam id, consequat nulla.
          </p>
        </div>
        <div className="col-md-6">
          <img src={placeholder} className="img-fluid" alt="" />
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-12">
          <h1>Apresentando a Comunidade</h1>
          <h2>Sed ut iaculis nunc, id euismod purus</h2>
          <p className="mt-4">
            Suspendisse tellus purus, euismod eget est ullamcorper, posuere iaculis elit.
          </p>
          <p>
            Pellentesque massa eros, dictum vitae ultrices id, maximus sit amet nisl. Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.  Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.  Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.
          </p>
          <p>
            Duis egestas eget turpis a finibus. Donec dapibus tortor non nisl tincidunt eleifend. Vivamus mattis tempor urna, auctor fringilla sem laoreet id. Sed lobortis sem at urna mollis consequat. Integer sed diam vitae eros eleifend lobortis vitae tristique ante. Nunc ex metus, dignissim a tortor sed, tempus vestibulum erat. Vivamus finibus finibus diam, vel faucibus erat ultrices sit amet. Donec vel felis ante. Nullam rhoncus elementum ipsum, quis lobortis lorem porttitor eu. Phasellus eget euismod sem, in congue magna. Maecenas vehicula euismod mollis.
          </p>
          <p style={{ paddingBottom: "60px" }}>
            Vestibulum ac erat sollicitudin, mattis diam id, consequat nulla.
          </p>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-12">
          <h1>Artigos Técnicos</h1>
          <h2>Sed ut iaculis nunc, id euismod purus</h2>
          <p className="mt-4">
            Suspendisse tellus purus, euismod eget est ullamcorper, posuere iaculis elit.
          </p>
          <p>
            Pellentesque massa eros, dictum vitae ultrices id, maximus sit amet nisl. Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.  Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.  Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.
          </p>
          <p>
            Duis egestas eget turpis a finibus. Donec dapibus tortor non nisl tincidunt eleifend. Vivamus mattis tempor urna, auctor fringilla sem laoreet id. Sed lobortis sem at urna mollis consequat. Integer sed diam vitae eros eleifend lobortis vitae tristique ante. Nunc ex metus, dignissim a tortor sed, tempus vestibulum erat. Vivamus finibus finibus diam, vel faucibus erat ultrices sit amet. Donec vel felis ante. Nullam rhoncus elementum ipsum, quis lobortis lorem porttitor eu. Phasellus eget euismod sem, in congue magna. Maecenas vehicula euismod mollis.
          </p>
          <p style={{ paddingBottom: "60px" }}>
            Vestibulum ac erat sollicitudin, mattis diam id, consequat nulla.
          </p>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-6">
          <h1>Jornal SAMPE Brasil</h1>
          <h2>Sed ut iaculis nunc, id euismod purus</h2>
          <p className="mt-4">
            Suspendisse tellus purus, euismod eget est ullamcorper, posuere iaculis elit.
          </p>
          <p>
            Pellentesque massa eros, dictum vitae ultrices id, maximus sit amet nisl.
          </p>
          <p>
            Cras pretium sapien quis erat tincidunt scelerisque. Quisque aliquam nunc sit amet urna efficitur, eget dictum dolor varius.
          </p>
          <p style={{ paddingBottom: "60px" }}>
            Vestibulum ac erat sollicitudin, mattis diam id, consequat nulla.
          </p>
        </div>
        <div className="col-md-6">
          <img src={placeholder} className="img-fluid" alt="" />
        </div>
      </FolderRow>
    </>
  );
}

export default Example;
