import SimpleRow from 'components/SimpleRow/SimpleRow';
import FolderRow from 'components/FolderRow/FolderRow';
import Banner from 'components/Banner/Banner';

import newsletter from 'resources/img/newsletter.png';
import artigos from 'resources/img/artigos.png';

function Page() {
  return (
    <>
      <Banner text="Publicações" />
      <SimpleRow backgroundColor="#f5f8ff" className="pb-5">

        <div className="col-md-8" style={{ marginBottom: "50px" }}>
          <h1>Artigos Técnicos</h1>
          <ul style={{ paddingLeft: "15px" }}>
            <li>
              <a className="linkedInBigLink" rel="noreferrer noopener" target="_blank" href="https://www.linkedin.com/feed/update/urn:li:activity:6889668446682931200"><i className="fas fa-external-link-alt mr-1"></i>
                RECICLAGEM DE COMPÓSITOS, DE BASE TERMOFIXO
              </a>
              <p>
                <b>
                  Autor(a): Marcos França, da Olin Corporation Brasil
                </b>
              </p>
            </li>
            <li>
              <a className="linkedInBigLink" rel="noreferrer noopener" target="_blank" href="https://www.linkedin.com/posts/sampe-brasil_infus%C3%A3o-em-processos-de-manufatura-de-p%C3%A1-activity-6763601042463621120-4WdG"><i className="fas fa-external-link-alt mr-1"></i>
                INFUSÃO EM PROCESSOS DE MANUFATURA DE PÁ EÓLICA
              </a>
              <p>
                <b>
                  Autor(a): Evando Correia, Engenheiro de Processos na Aeris Energy
                </b>
              </p>
            </li>
            <li>
              <a className="linkedInBigLink" rel="noreferrer noopener" target="_blank" href="https://www.linkedin.com/posts/sampe-brasil_artigo-processo-de-pultrus%C3%A3o-para-perfis-activity-6791008872678117378-68eN"><i className="fas fa-external-link-alt mr-1"></i>
                PROCESSO DE PULTRUSÃO PARA PERFIS ESTRUTURAIS
              </a>
              <p>
                <b>
                  Autor(a): Maria Luiza Ribas, Engenharia de Materiais Compostos na ENMAC
                </b>
              </p>
            </li>
            <li>
              <a className="linkedInBigLink" rel="noreferrer noopener" target="_blank" href="https://www.linkedin.com/posts/sampe-brasil_artigo-sampe-brasil-activity-6808776529489883137-h87E"><i className="fas fa-external-link-alt mr-1"></i>
                POSTES PRODUZIDOS EM MATERIAIS COMPÓSITOS POR ENROLAMENTO FILAMENTAR
              </a>
              <p>
                <b>
                  Autor(a): Elton Landgraf Guiguer, Engenheiro de Produtos na Owens Corning
                </b>
              </p>
            </li>
            <li>
              <a className="linkedInBigLink" rel="noreferrer noopener" target="_blank" href="https://www.linkedin.com/feed/update/urn:li:activity:6808776529489883137/"><i className="fas fa-external-link-alt mr-1"></i>
                EMBARCAÇÕES EM COMPÓSITOS: MATERIAIS E PROCESSOS DE FABRICAÇÃO
              </a>
              <p>
                <b>
                  Autor(a): Christiane Vieira, Engenheira Química na M3 Engenharia Química | Azimut Yachts
                </b>
              </p>
            </li>
          </ul>
          <a className="linkedInBigLink" rel="noreferrer noopener" target="_blank" href="https://www.linkedin.com/in/alvaroquintaneiro/"><i className="fab fa-linkedin mr-1"></i>Alvaro Quintaneiro da Solva</a>
        </div>
        <div className="col-md-4">
          <img src={artigos} className="img-fluid" alt="" />
        </div>
      </SimpleRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-8">
          <h1>Boletim de Notícias</h1>
          <p>
            Em breve!
          </p>
          <a className="linkedInBigLink" rel="noreferrer noopener" target="_blank" href="https://www.linkedin.com/in/maria-isabelle-neves/"><i className="fab fa-linkedin mr-1"></i>Maria Isabelle Neves</a>
        </div>
        <div className="col-md-4">
          <img src={newsletter} className="img-fluid" alt="" />
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#f5f8ff">
        <div className="col-md-12">
          <h1>Livros</h1>
          <div className="row">

            <div className="col-md-6">
              <ul style={{ paddingLeft: "15px" }}>
                <li>
                  <p className="mb-0">
                    ADVANCED MATERIALS SCIENCE AND ENGINEERING OF CARBON
                  </p>
                  <p>
                    <b>
                      Hidetaka Konno, Masahiro Toyoda, Michio Inagaki e Feiyu Kang
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    HANDBOOK OF ADVANCED MATERIALS
                  </p>
                  <p>
                    <b>
                      James K. Wessel
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    HANDBOOK OF ADVANCED MATERIALS TESTING
                  </p>
                  <p>
                    <b>
                      Louise Ferrante
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    ADVANCED ENGINEERING MATERIALS AND MODELING
                  </p>
                  <p>
                    <b>
                      N. Arul Murugan e Rajeev Ahuja
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    ADVANCED MATERIALS SOURCE BOOK
                  </p>
                  <p>
                    <b>
                      Jon Binner
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    ADVANCED MATERIALS PROCESSING AND MANUFACTURING
                  </p>
                  <p>
                    <b>
                      Yogesh Jaluria
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    CHEMISTRY OF ADVANCED MATERIALS: AN OVERVIEW
                  </p>
                  <p>
                    <b>
                      Leonard V. Interrante
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    ADVANCED MATERIALS, POLYMERS, AND COMPOSITES
                  </p>
                  <p>
                    <b>
                      Omari Mukbaniani, Tamara Tatrishvili e Marc J. M. Abadie
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    ADVANCED MECHANICS OF MATERIALS
                  </p>
                  <p>
                    <b>
                      Arthur Boresi e Richard J. Schmidt
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    NANOSTRUCTURED AND ADVANCED MATERIALS FOR FUEL CELLS
                  </p>
                  <p>
                    <b>
                      San Ping Jiang e Pei Kang Shen
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    ENERGY-SUSTAINABLE ADVANCED MATERIALS
                  </p>
                  <p>
                    <b>
                      Mark Alston e Timothy N. Lambert
                    </b>
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul style={{ paddingLeft: "15px" }}>
                <li>
                  <p className="mb-0">
                    ADVANCED CERAMIC MATERIALS
                  </p>
                  <p>
                    <b>
                      Magdalena Szutkowska e Rosario A. Gerhardt
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    SINTERING OF ADVANCED MATERIALS
                  </p>
                  <p>
                    <b>
                      Zhigang Zak Fang
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    ADVANCED MATERIALS FOR INTERCONNECTIONS
                  </p>
                  <p>
                    <b>
                      Th. Gessner
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    RADIATION TECHNOLOGY FOR ADVANCED MATERIALS
                  </p>
                  <p>
                    <b>
                      Guozhong Wu, Maolin Zhai e Mozhen Wang
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    ADVANCED MATERIALS SCIENCE AND ENGINEERING OF CARBON
                  </p>
                  <p>
                    <b>
                      Michio Inagaki, Feiyu Kang, Masahiro Toyoda e Hidetaka Konno
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    ADVANCED FUNCTIONAL MATERIALS
                  </p>
                  <p>
                    <b>
                      Biplab Sanyal e Olle Eriksson
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    COMPÓSITOS 1 – MATERIAIS, PROCESSOS, APLICAÇÕES, DESEMPENHOS E TENDÊNCIAS
                  </p>
                  <p>
                    <b>
                      ALMACO
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    COMPÓSITOS 2 – TECNOLOGIA DE PROCESSOS
                  </p>
                  <p>
                    <b>
                      ALMACO
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    COMPÓSITOS 3 – MERCADO
                  </p>
                  <p>
                    <b>
                      ALMACO
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    COMPÓSITOS 4 – MOLDES
                  </p>
                  <p>
                    <b>
                      ALMACO
                    </b>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    COMPÓSITO 5 – ALTO DESEMPENHO
                  </p>
                  <p>
                    <b>
                      ALMACO
                    </b>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </FolderRow>
      <FolderRow backgroundColor="#ffffff">
        <div className="col-md-12">
          <h1>Perguntas e Respostas</h1>
          <p>Em breve</p>
          <p className="mt-4">
            <a className="linkedInBigLink" rel="noreferrer noopener" target="_blank" href="https://www.linkedin.com/in/christiane-vieira-965292b/"><i className="fab fa-linkedin mr-1"></i>Christiane Vieira</a>
          </p>
        </div>
      </FolderRow>
    </>
  );
}

export default Page;
