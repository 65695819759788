import { FunctionComponent } from "react";
import "./SimpleRow.scss";

const SimpleRow: FunctionComponent<{
  first?: boolean;
  children?: any;
  backgroundColor?: string;
  className: any;
  padBottom?: boolean;
}> = ({
  first = true,
  children,
  backgroundColor,
  className,
  padBottom = false,
}) => {
  return (
    <div
      className={className + (first ? " menu-padding " : "")}
      style={{
        backgroundColor: backgroundColor,
        paddingBottom: padBottom ? "100px" : "",
      }}
    >
      <div className="container">
        <div className="row mt-5">{children}</div>
      </div>
    </div>
  );
};

export default SimpleRow;

