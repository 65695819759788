import SimpleRow from 'components/SimpleRow/SimpleRow';
import Banner from 'components/Banner/Banner';

function Page() {
  return (
    <>
      <Banner text="Nossos Apoiadores" />
      <SimpleRow backgroundColor="#f5f8ff">
        <div className="col-md-12 mb-5 pb-5">
          <div className='row'>
            <div className='col-md-4'>
            <h2>Associados Pleno</h2>
              <p>
                <ul style={{paddingLeft: "15px"}}>
                  <li>Aeris Energy</li>
                  <li>Aerovac</li>
                  <li>Alan Harper Composites</li>
                  <li>Alltec Composites</li>
                  <li>Baker Hughes</li>
                  <li>Boeing</li>
                  <li>Evonik</li>
                  <li>Gerdau Graphene</li>
                  <li>Huntsman</li>
                  <li>Magna Compósitos</li>
                  <li>Lin</li>
                  <li>Solvay</li>
                  <li>Tencate Geosynthetics</li>
                  <li>Texiglass</li>
                </ul>
              </p>
            </div>
            <div className='col-md-4'>
              <h2>Pequenas e Médias Empresas (PMEs)</h2>
              <p>
                <ul style={{paddingLeft: "15px"}}>
                  <li>Forza Composites</li>
                  <li>M3 Engenharia Química</li>
                  <li>Subiter</li>
                  <li>TNS Nano</li>
                  <li>Rallc</li>
                </ul>
              </p>
            </div>
            <div className='col-md-4'>
              <h2>Instituições Técnicas</h2>
              <p>
                <ul style={{paddingLeft: "15px"}}>
                  <li>PUC Rio de Janeiro</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </SimpleRow>
    </>
  );
}

export default Page;
