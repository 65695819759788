import SimpleRow from 'components/SimpleRow/SimpleRow';
import examplePdf from 'resources/example.pdf'
import Banner from 'components/Banner/Banner';

function Page() {
  return (
    <>
      <Banner text="Calendário" />
      <SimpleRow backgroundColor="#f5f8ff">
        <div className="col-md-12 mb-5 pb-5">
          <ul style={{ listStyle: "none", fontSize: "22px", paddingLeft: "0" }}>
            <li>
              <a  href="https://365.sampe.org/events" target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt mr-1"></i>Calendário de atividades internacional</a>
            </li>
            <li className="d-none">
              <a href={examplePdf} download="example" ><i class="fas fa-file-pdf" style={{ color: "#006699" }}></i> Calendário de atividades nacional</a>
            </li>
          </ul>
        </div>
      </SimpleRow>
    </>
  );
}

export default Page;
