import SimpleRow from 'components/SimpleRow/SimpleRow';

import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';

function Page() {
  SwiperCore.use([Autoplay]);
  return (
    <>
      <SimpleRow className="bg-primary text-white">
        <div className="col-md-12 pb-5 mb-5">
          <h1>Missão</h1>
          <p>
            Aprimorar a disseminação de conhecimento de materiais avançados e processos tecnológicos envolvidos, com foco no desenvolvimento do setor e no progresso do País.
          </p>
          <h1>Visão 2025</h1>
          <p>
            Ser o ponto de encontro dos profissionais do setor, construindo a maior comunidade técnica do segmento de materiais e processos de engenharia avançados.          </p>
          <h1>Propósito</h1>
          <p>
            Conectar pessoas, construir e compartilhar conhecimento para a evolução tecnológica do Brasil.
          </p>
        </div>
      </SimpleRow>
    </>
  );
}

export default Page;
