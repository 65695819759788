import React from 'react';

import facebookMobile from 'resources/sampe_facebook.png'
import linkedinMobile from 'resources/sampe_linkedin.png'
import youtubeMobile from 'resources/sampe_youtube.png'

import Phone from 'resources/hand-phone.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';

function Cellphone() {
  SwiperCore.use([Autoplay]);
  return (
    <div style={{ position: "relative" }}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        autoplay={true}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        style={{ backgroundColor: "#000000", width: "41.8%", height: "65.5%", position: "absolute", top: "5.2%", left: "39.4%", zIndex: 0 }}
      >
        <SwiperSlide>
          <a href="https://www.facebook.com/sampebrasiloficial/" target="_blank" rel="noreferrer">
            <img className="img-fluid" style={{ width: "100%" }} src={facebookMobile} alt="" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.youtube.com/channel/UCpNurLJD625AKytq1o1YUeA" target="_blank" rel="noreferrer">
            <img className="img-fluid" style={{ width: "100%" }} src={youtubeMobile} alt="" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.linkedin.com/company/sampe-brasil/mycompany/" target="_blank" rel="noreferrer">
            <img className="img-fluid" style={{ width: "100%" }} src={linkedinMobile} alt="" />
          </a>
        </SwiperSlide>
      </Swiper>
      <img src={Phone} className="img-fluid" style={{ width: "400px", position: "relative", userSelect: "none", pointerEvents: "none" }} alt="" />
    </div>
  );
}

export default Cellphone;
