import "./FloatButton.scss";

import {
  NavLink
} from "react-router-dom";

function Component() {
  return (
    <>
      <NavLink className="FloatButton" to="/contato">
        <i class="fas fa-comment" />
      </NavLink>
    </>
  );
}

export default Component;
