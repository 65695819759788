import veirano from 'resources/apoiadores/veirano.png'
import myPlaceOffice from 'resources/apoiadores/myPlaceOffice.png'
import symnetics from 'resources/apoiadores/symnetics.png'
import acr from 'resources/apoiadores/acr.png'
import unnamed from 'resources/apoiadores/unnamed.png'
import artsim from 'resources/apoiadores/artsim.jpg'

import aeris from 'resources/associados/aeris.png'
import aerovac from 'resources/associados/aerovac.png'
import ah from 'resources/associados/ah.png'
import alltec from 'resources/associados/alltec.png'
import magna from 'resources/associados/magna.png'
import inbra from 'resources/associados/inbra.png'
import evonik from 'resources/associados/evonik.png'
import huntsman from 'resources/associados/huntsman.png'
import solvay from 'resources/associados/solvay.png'
import texiglass from 'resources/associados/texiglass.png'
import tencate from 'resources/associados/tencate.png'
import boeing from 'resources/associados/boeing.png'
import olin from 'resources/associados/olin.jpg'

import FolderRow from 'components/FolderRow/FolderRow';

import "./Sponsors.scss";

function Sponsors() {
  return (
    <>
      <FolderRow longPadBottom={true} backgroundColor="#D1DEE8" className="Sponsors">
        <div className="col-12 header">
          <h1 className="text-center mb-5">Associados Corporativos</h1>
        </div>
        <div className="self-center col-md-3">
          <img src={aeris} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-3">
          <img src={aerovac} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-3">
          <img src={ah} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-3">
          <img src={alltec} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-3">
          <img src={boeing} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-3">
          <img src={evonik} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-3">
          <img src={inbra} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-3">
          <img src={huntsman} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-3">
          <img src={magna} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-3">
          <img src={olin} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-3">
          <img src={solvay} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-3">
          <img src={tencate} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-12">
          <img src={texiglass} alt="" className="img-fluid" />
        </div>
      </FolderRow>
      <FolderRow longPadBottom={false} backgroundColor="#ffffff" className="Sponsors">        
        <div className="col-12 header">
          <h1 className="text-center mb-5">Apoiadores Institucionais</h1>
        </div>
        <div className="self-center col-md-2">
          <img src={acr} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-2">
          <img src={artsim} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-2">
          <img src={unnamed} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-2">
          <img src={myPlaceOffice} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-2">
          <img src={symnetics} alt="" className="img-fluid" />
        </div>
        <div className="self-center col-md-2" >
          <img src={veirano} alt="" className="img-fluid" />
        </div>
      </FolderRow>
    </>
  );
}

export default Sponsors;
